<template>
  <v-container>
    <h1 class="mb-4">Blog</h1>
    <v-card>
      <v-divider></v-divider>
      <v-card-text>
        Coming soon...
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
export default ({
  name: 'Blog',
  return: {

  }
})
</script>
<style lang="scss">

</style>