<template>
  <v-container>
    <h1 class="mb-4">Dashboard</h1>
    <!-- <v-card> -->
      <!-- TODO: Chat with LLM -->
    <!-- </v-card> -->
    <v-card>
      <v-card-text class="hero">
        <img src="https://firebasestorage.googleapis.com/v0/b/hydro-ottawa-ai.appspot.com/o/under-construction.webp?alt=media&token=ad035b93-91fb-40fc-a5f9-c041aaf1564d">
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
export default ({
  name: 'Dashboard',
  data() {
    return {
      // 
    }
  }
})
</script>
<style lang="scss">
  .hero {
    position: relative;
    img {
      width: 100%;
      display: block;
    }
  }
</style>