import Vue from "vue";
import VueRouter from "vue-router";
import { getAuth } from "firebase/auth";
import { getFirestore, doc, getDoc, collection } from "firebase/firestore";

// ------------ Communications ------------
import CommunicationsImagen from "../views/Communications/Imagen.vue";
import CommunicationsMusic from "../views/Communications/Music.vue";
import CommunicationsPodcasts from "../views/Communications/Podcasts.vue";
// ------------ Corporate ------------
// import CorporateFirstProject from "../views/Corporate/FirstProject.vue";
// import CorporateFirstExperiment from "../views/Corporate/FirstExperiment.vue";
// ------------ Customer Service ------------
import CustomerServiceAgentAssist from "../views/CustomerService/AgentAssist.vue";
import CustomerServiceCallAnalysis from "../views/CustomerService/CallAnalysis.vue";
import CustomerServiceSmartSearch from "../views/CustomerService/SmartSearch.vue";
import CustomerServiceArtificialAgent from "../views/CustomerService/ArtificialAgent.vue";
// ------------ Distribution ------------
// import DistributionFirstProject from "../views/Distribution/FirstProject.vue";
// import DistributionFirstExperiment from "../views/Distribution/FirstExperiment.vue";
// ------------ Finance ------------
// import FinanceFirstProject from "../views/Finance/FirstProject.vue";
import FinanceBillPrediction from "../views/Finance/BillPrediction.vue";
// ------------ Generation ------------
// import GenerationFirstProject from "../views/Generation/FirstProject.vue";
// import GenerationFirstExperiment from "../views/Generation/FirstExperiment.vue";
// ------------ Human Resources ------------
// import HumanResourcesFirstProject from "../views/HumanResources/FirstProject.vue";
import HumanResourcesWorkforceAutomation from "../views/HumanResources/WorkforceAutomation.vue";
// ------------ Infrastructure ------------
// import InfrastructureFirstProject from "../views/Infrastructure/FirstProject.vue";
// import InfrastructureFirstExperiment from "../views/Infrastructure/FirstExperiment.vue";
// ------------ Legal ------------
// import LegalFirstProject from "../views/Legal/FirstProject.vue";
// import LegalFirstExperiment from "../views/Legal/FirstExperiment.vue";
// ------------ Operations ------------
import OperationsSwitchOrders from "../views/Operations/SwitchOrders.vue";
import OperationsNameplateAutomation from "../views/Operations/NameplateAutomation.vue";
import OperationsDamageAssessment from "../views/Operations/DamageAssessment.vue";
import OperationsForestry from "../views/Operations/Forestry.vue";
// ------------ Regulatory ------------
import RegulatoryRateApplication from "../views/Regulatory/RateApplication.vue";
// ------------ Technology ------------
// import TechnologyFirstProject from "../views/Technology/FirstProject.vue";
import TechnologyChatAssistant from "../views/Technology/ChatAssistant.vue";

// ------------ Common ------------
import Blog from "../views/Blog.vue";
import Landing from "../views/Landing.vue";
import Login from "../views/Login.vue";
import Team from "../views/Team.vue";
import RateExplorer from "../views/RateExplorer.vue";

// ------------ Errors ------------
import Forbidden from "../views/errors/Forbidden.vue";
import NotFound from "../views/errors/NotFound.vue";
import Unknown from "../views/errors/Unknown.vue";

// ------------ Private ------------
import Dashboard from "../views/Dashboard.vue";


Vue.use(VueRouter);

const routes = [
  // ------------ Common ------------
  {
    path: "/",
    name: "Landing",
    component: Landing,
    meta: {
      scope: "public",
    },
  },
  {
    path: "/blog",
    name: "Blog",
    component: Blog,
    meta: {
      scope: "private",
      roles: ["Employee"],
    },
  },
  {
    path: "/team",
    name: "Team",
    component: Team,
    meta: {
      scope: "private",
      roles: ["Employee"],
    },
  },

  // ------------ Private ------------
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    meta: {
      scope: "private",
      roles: ["Employee"],
    },
  },
  {
    path: "/explorer",
    name: "RateExplorer",
    component: RateExplorer,
    meta: {
      scope: "private",
      roles: ["Employee"],
    },
  },

  // ------------ Communications ------------
  {
    path: "/communications/imagen",
    name: "CommunicationsImagen",
    component: CommunicationsImagen,
    meta: {
      scope: "private",
      roles: ["Communications", "Pioneer"],
    },
  },
  {
    path: "/communications/music",
    name: "CommunicationsMusic",
    component: CommunicationsMusic,
    meta: {
      scope: "private",
      roles: ["Communications", "Pioneer"],
    },
  },
  {
    path: "/communications/podcasts",
    name: "CommunicationsPodcasts",
    component: CommunicationsPodcasts,
    meta: {
      scope: "private",
      roles: ["Communications", "Pioneer"],
    },
  },
  // ------------ Corporate ------------
  // No projects or experiments
  // ------------ Customer Service ------------
  {
    path: "/customer-service/agent-assist",
    name: "CustomerServiceAgentAssist",
    component: CustomerServiceAgentAssist,
    meta: {
      scope: "private",
      roles: ["Customer Service"],
    },
  },
  {
    path: "/customer-service/call-analysis",
    name: "CustomerServiceCallAnalysis",
    component: CustomerServiceCallAnalysis,
    meta: {
      scope: "private",
      roles: ["Customer Service"],
    },
  },
  {
    path: "/customer-service/smart-search",
    name: "CustomerServiceSmartSearch",
    component: CustomerServiceSmartSearch,
    meta: {
      scope: "private",
      roles: ["Customer Service"],
    },
  },
  {
    path: "/customer-service/artificial-agent",
    name: "CustomerServiceArtificialAgent",
    component: CustomerServiceArtificialAgent,
    meta: {
      scope: "private",
      roles: ["Customer Service", "Pioneer"],
    },
  },
  // ------------ Distribution ------------
  // No projects or experiments
  // ------------ Finance ------------
  {
    path: "/finance/bill-prediction",
    name: "FinanceBillPrediction",
    component: FinanceBillPrediction,
    meta: {
      scope: "private",
      roles: ["Finance", "Pioneer"],
    },
  },
  // ------------ Generation ------------
  // No projects or experiments
  // ------------ Human Resources ------------
  {
    path: "/human-resources/workforce-automation",
    name: "HumanResourcesWorkforceAutomation",
    component: HumanResourcesWorkforceAutomation,
    meta: {
      scope: "private",
      roles: ["Human Resources", "Pioneer"],
    },
  },
  // ------------ Infrastructure ------------
  // No projects or experiments
  // ------------ Legal ------------
  // No projects or experiments
  // ------------ Operations ------------
  {
    path: "/operations/switch-orders",
    name: "OperationsSwitchOrders",
    component: OperationsSwitchOrders,
    meta: {
      scope: "private",
      roles: ["Operations"],
    },
  },
  {
    path: "/operations/nameplate-automation",
    name: "OperationsNameplateAutomation",
    component: OperationsNameplateAutomation,
    meta: {
      scope: "private",
      roles: ["Operations", "Pioneer"],
    },
  },
  {
    path: "/operations/damage-assessment",
    name: "OperationsDamageAssessment",
    component: OperationsDamageAssessment,
    meta: {
      scope: "private",
      roles: ["Operations", "Pioneer"],
    },
  },
  {
    path: "/operations/forestry",
    name: "OperationsForestry",
    component: OperationsForestry,
    meta: {
      scope: "private",
      roles: ["Operations", "Pioneer"],
    },
  },
  // ------------ Regulatory ------------
  {
    path: "/regulatory/rate-application",
    name: "RegulatoryRateApplication",
    component: RegulatoryRateApplication,
    meta: {
      scope: "private",
      roles: ["Regulatory"],
    },
  },
  // ------------ Technology ------------
  {
    path: "/technology/chat-assistant",
    name: "TechnologyChatAssistant",
    component: TechnologyChatAssistant,
    meta: {
      scope: "private",
      roles: ["Technology", "Pioneer"],
    },
  },
  
  // ------------ Errors ------------
  {
    path: "/403",
    name: "Forbidden",
    component: Forbidden,
    meta: {
      scope: "public",
    },
  },
  {
    path: "/520",
    name: "Unknown",
    component: Unknown,
    meta: {
      scope: "public",
    },
  },
  {
    path: "*",
    name: "NotFound",
    component: NotFound,
    meta: {
      scope: "public",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeResolve((to, from, next) => {
  next();
  // ---------------------- Public ----------------------
  if (to.meta.scope == "public") {
    next();
  } else {
    let user = getAuth().currentUser;
    // ---------------------- Not Public ----------------------
    if (user) {
      // ---------------------- Logged In ----------------------
      // We need to check if the user has permissions to access the route
      // Get the user document
      const db = getFirestore();
      const userRef = doc(db, "users", user.uid);
      getDoc(userRef)
        .then((doc) => {
          if (doc.exists()) {
            // ----------- User exists -----------
            // Check if the user has the required permissions.
            // The user field roles has an array of roles. We need to ensure the role defined on each route matches the array.
            // or if the user is an admin
            let userRoles = doc.data().roles;
            if (to.meta.roles) {
              console.log("User Roles:", userRoles);
              if (userRoles.includes("Admin")) {
                // User is an admin
                next();
              // check if the user has all the required route roles (array)
              } else if (to.meta.roles.some((role) => userRoles.includes(role))) {
                // User has the required role
                next();
              } else {
                // User does not have the required role
                next({ name: "Forbidden" });
              }
            } else {
              // Route does not have roles defined
              next();
            }
          } else {
            // User does not exist
            next({ name: "Landing" });
          }
        })
        .catch((error) => {
          // Error getting user document
          console.error("Error getting user document:", error);
          next({ name: "Unknown" });
        });
    } else {
      // ---------------------- Not Logged In ----------------------
      next({ name: "Landing" });
    }
  }
});

export default router;
