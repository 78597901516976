<template>
  <div>
    <div ref="widgetContainer"></div> <!-- Container for the widget -->
    <v-btn color="primary" @click="initializeWidget">Rate Application Agent</v-btn>
  </div>
</template>
<script>
export default {
  name: 'RateApplicationSearch',
  props: ['user'],
  mounted() {
    this.loadWidgetScript();
  },
  methods: {
    loadWidgetScript() {
      // Dynamically load the widget script if not already loaded
      const scriptId = 'gen-widget-script';
      if (document.getElementById(scriptId)) return;
      const script = document.createElement('script');
      script.id = scriptId;
      script.src = "https://cloud.google.com/ai/gen-app-builder/client?hl=en_US";
      script.onload = this.initWidget;
      document.head.appendChild(script);
    },
    initWidget() {
      // Create and configure the widget
      const widget = document.createElement('gen-search-widget');
      widget.setAttribute('configId', '0dc0e396-f182-4841-9ed0-31edb81e4762');
      widget.setAttribute('location', 'us');
      widget.setAttribute('triggerId', 'searchWidgetTrigger');
      this.$refs.widgetContainer.appendChild(widget);
      // Set the JWT for the widget
      this.setAuthToken(widget);
    },
    setAuthToken(widget) {
      // Obtain the JWT from Firebase and set it on the widget
      this.user.getIdToken(true).then(jwt => {
        widget.authToken = jwt; // Set the JWT
      }).catch(function(error) {
        console.error("Failed to get ID token:", error);
      });
    },
    initializeWidget() {
      // Programmatically trigger the search widget
      const trigger = document.createElement('input');
      trigger.id = 'searchWidgetTrigger';
      trigger.style.display = 'none'; // Hide it since it's not needed visibly
      document.body.appendChild(trigger);
      trigger.click();
      document.body.removeChild(trigger);
    }
  }
}
</script>
