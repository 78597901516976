<template>
  <div class="d-flex flex-column">
    <div class="text-h5">Available Rate Plans</div>
    <!-- <h3 class="my-4">Available Rate Plans</h3> -->
    <div class="py-4">
      In Ontario, there are 3 available rate plans. Select one to learn more about it.
    </div>
    <div class="d-flex flex-row">
      <v-card class="flex-1 d-flex flex-column ma-2" @click="selectedPlan = (selectedPlan == data.plan.current ? '' : data.plan.current)" :color="selectedPlan == data.plan.current ? '#ededed' : 'white'">
        <div class="pa-4 d-flex flex-column flex-1">
          <div v-if="data.plan.current == 'tiered'" class="font-weight-bold">Tiered Rates</div>
          <div v-if="data.plan.current == 'tou'" class="font-weight-bold">Time-of-Use Rates</div>
          <div v-if="data.plan.current == 'ulo'" class="font-weight-bold">Ultra-Low Overnight Rates</div>
          <div class="text-caption font-italic grey--text">Your current plan</div>
          <div v-if="data.plan.current == 'tiered'" class="mt-2 text-caption">
            Charges vary depending on how much electricity you use overall in a month and the season.<v-icon color="grey" small>mdi-information</v-icon>
          </div>
          <div v-if="data.plan.current == 'tou'" class="mt-2 text-caption">
            Charges vary depending on the time of day, day of the week, and season.<v-icon color="grey" small>mdi-information</v-icon>
          </div>
          <div v-if="data.plan.current == 'ulo'" class="mt-2 text-caption">
            Charges vary depending on the time of day and day of the week.<v-icon color="grey" small>mdi-information</v-icon>
          </div>
          <div class="mt-3 font-italic">
            You pay an average of {{ data.cost[data.plan.current].total.sum | currency }} per month*
          </div>
        </div>
        <div class="cost-banner-grey py-2 font-weight-bold grey--text">
          Your current plan
        </div>
      </v-card>
      <v-card v-if="data.plan.current != 'tiered'" class="flex-1 d-flex flex-column ma-2" @click="selectedPlan = (selectedPlan == 'tiered' ? '' : 'tiered')" :color="selectedPlan == 'tiered' ? '#ededed' : 'white'">
        <div class="pa-4 d-flex flex-column flex-1">
          <div class="font-weight-bold">Tiered Rates</div>
          <div class="text-caption font-italic grey--text">Alternative plan</div>
          <div class="mt-2 text-caption">Charges vary depending on how much electricity you use overall in a month and the season.<v-icon color="grey" small>mdi-information</v-icon></div>
          <div class="mt-3 font-italic">You would pay an average of {{ data.cost.tiered.total.sum | currency }} per month*</div>
        </div>
        <div class="cost-banner-green py-2 font-weight-bold green--text" v-if="data.cost.tiered.total.sum < data.cost[data.plan.current].total.sum">
          Savings of {{ data.cost[data.plan.current].total.sum - data.cost.tiered.total.sum | currency }}/month
        </div>
        <div class="cost-banner-red py-2 font-weight-bold red--text" v-else>
          Increase of {{ data.cost.tiered.total.sum - data.cost[data.plan.current].total.sum | currency }}/month
        </div>
      </v-card>
      <v-card v-if="data.plan.current != 'tou'" class="flex-1 d-flex flex-column ma-2" @click="selectedPlan = (selectedPlan == 'tou' ? '' : 'tou')" :color="selectedPlan == 'tou' ? '#ededed' : 'white'">
        <div class="pa-4 d-flex flex-column flex-1">
          <div class="font-weight-bold">Time-of-Use Rates</div>
          <div class="text-caption font-italic grey--text">Alternative plan</div>
          <div class="mt-2 text-caption">Charges vary depending on the time of day, day of the week, and season.<v-icon color="grey" small>mdi-information</v-icon></div>
          <div class="mt-3 font-italic">You would pay an average of {{ data.cost.tou.total.sum | currency }} per month*</div>
        </div>
        <div class="cost-banner-green py-2 font-weight-bold green--text" v-if="data.cost.tou.total.sum < data.cost[data.plan.current].total.sum">
          Savings of {{ data.cost[data.plan.current].total.sum - data.cost.tou.total.sum | currency }}/month
        </div>
        <div class="cost-banner-red py-2 font-weight-bold red--text" v-else>
          Increase of {{ data.cost.tou.total.sum - data.cost[data.plan.current].total.sum | currency }}/month
        </div>
      </v-card>
      <v-card v-if="data.plan.current != 'ulo'" class="flex-1 d-flex flex-column ma-2" @click="selectedPlan = (selectedPlan == 'ulo' ? '' : 'ulo')" :color="selectedPlan == 'ulo' ? '#ededed' : 'white'">
        <div class="pa-4 d-flex flex-column flex-1">
          <div class="font-weight-bold">Ultra-Low Overnight Rates</div>
          <div class="text-caption font-italic grey--text">Alternative plan</div>
          <div class="mt-2 text-caption">Charges vary depending on the time of day and day of the week.<v-icon color="grey" small>mdi-information</v-icon></div>
          <div class="mt-3 font-italic">You would pay an average of {{ data.cost.ulo.total.sum | currency }} per month*</div>
        </div>
        <div class="cost-banner-green py-2 font-weight-bold green--text" v-if="data.cost.ulo.total.sum < data.cost[data.plan.current].total.sum">
          Savings of {{ data.cost[data.plan.current].total.sum - data.cost.ulo.total.sum | currency }}/month
        </div>
        <div class="cost-banner-red py-2 font-weight-bold red--text" v-else>
          Increase of {{ data.cost.ulo.total.sum - data.cost[data.plan.current].total.sum | currency }}/month
        </div>
      </v-card>
    </div>
    <!-- ---------------------- Actions ---------------------- -->
    <div class="d-flex flex-row my-4 align-center justify-center">
      <div class="flex-1 text-caption font-italic grey--text">*assuming the same consumption as last year</div>
      <!-- <v-spacer></v-spacer> -->
      <v-btn class="mr-4">Switch Plan</v-btn>
      <v-btn @click="$emit('nextStep')" color="#005794" dark>Explore your data</v-btn>
    </div>
    <!-- ---------------------- Rates Explained ---------------------- -->
    <v-card v-if="selectedPlan == ''" class="ma-2 pa-4">
      <h3 class="mb-4">Rate Comparison</h3>
      <div class="d-flex flex-row" style="height: 500px">
        <!-- Tiered Rates -->
        <div class="d-flex flex-column flex-1 mx-4">
          <div class="d-flex flex-row flex-1 align-end">
            <div class="d-flex flex-column flex-1 justify-end" style="height: 100%">
              <div class="my-1">10.3 ¢</div>
              <div class="bar-segment tiered-tier1-segment" :style="{'height': (10.3/28.6)*100 + '%'}"></div>
              <div class="my-1" style="min-height: 50px">Tier 1</div>
            </div>
            <div class="d-flex flex-column flex-1 justify-end" style="height: 100%">
              <div class="my-1">12.5 ¢</div>
              <div class="bar-segment tiered-tier2-segment" :style="{'height': (12.5/28.6)*100 + '%'}"></div>
              <div class="my-1" style="min-height: 50px">Tier 2</div>
            </div>
          </div>
          <div class="overline">Tiered Rates</div>
        </div>
        <!-- TOU Rates -->
        <div class="d-flex flex-column flex-1 mx-4">
          <div class="d-flex flex-row flex-1 align-end">
            <div class="d-flex flex-column flex-1 justify-end" style="height: 100%">
              <div class="my-1">8.7 ¢</div>
              <div class="bar-segment tou-off-segment" :style="{'height': (8.7/28.6)*100 + '%'}"></div>
              <div class="my-1" style="min-height: 50px">Off-peak</div>
            </div>
            <div class="d-flex flex-column flex-1 justify-end" style="height: 100%">
              <div class="my-1">12.2 ¢</div>
              <div class="bar-segment tou-mid-segment" :style="{'height': (12.2/28.6)*100 + '%'}"></div>
              <div class="my-1" style="min-height: 50px">Mid-peak</div>
            </div>
            <div class="d-flex flex-column flex-1 justify-end" style="height: 100%">
              <div class="my-1">18.2 ¢</div>
              <div class="bar-segment tou-on-segment" :style="{'height': (18.2/28.6)*100 + '%'}"></div>
              <div class="my-1" style="min-height: 50px">On-peak</div>
            </div>
          </div>
          <div class="overline">Time-of-Use Rates</div>
        </div>
        <!-- ULO Rates -->
        <div class="d-flex flex-column flex-1 mx-4">
          <div class="d-flex flex-row flex-1 align-end">
            <div class="d-flex flex-column flex-1 justify-end" style="height: 100%">
              <div class="my-1">2.8 ¢</div>
              <div class="bar-segment ulo-overnight-segment" :style="{'height': (2.8/28.6)*100 + '%'}"></div>
              <div class="my-1" style="min-height: 50px">Ultra-low overnight</div>
            </div>
            <div class="d-flex flex-column flex-1 justify-end" style="height: 100%">
              <div class="my-1">8.7 ¢</div>
              <div class="bar-segment ulo-weekend-segment" :style="{'height': (8.7/28.6)*100 + '%'}"></div>
              <div class="my-1" style="min-height: 50px">Weekend off-peak</div>
            </div>
            <div class="d-flex flex-column flex-1 justify-end" style="height: 100%">
              <div class="my-1">12.2 ¢</div>
              <div class="bar-segment ulo-mid-segment" :style="{'height': (12.2/28.6)*100 + '%'}"></div>
              <div class="my-1" style="min-height: 50px">Mid-peak</div>
            </div>
            <div class="d-flex flex-column flex-1 justify-end" style="height: 100%">
              <div class="my-1">28.6 ¢</div>
              <div class="bar-segment ulo-on-segment" :style="{'height': (28.6/28.6)*100 + '%'}"></div>
              <div class="my-1" style="min-height: 50px">On-peak</div>
            </div>
          </div>
          <div class="overline">Ultra-low Overnight Rates</div>
        </div>
      </div>
    </v-card>
    <!-- ---------------------- Tiered Explained ---------------------- -->
    <v-card v-if="selectedPlan == 'tiered'" class="ma-2 pa-4">
      <h3 class="mb-4">Tiered Rate Plan</h3>
      <div class="d-flex flex-row mt-4">
        <div class="flex-1 px-4">
          With Tiered rates, the price depends on how much electricity customers use overall in a month<br>
          <br>
          Customers under Tiered rates pay one set rate for the electricity they consume up to a predetermined kilowatt-hour (kWh) threshold each month. Once customers exceed the monthly threshold, they are billed at a higher rate per kWh for all additional electricity consumed in that month.<br>
          <br>
          The threshold changes with the seasons for residential customers. The threshold for small business customers is the same all year round.
          <div class="hol-table d-flex flex-column my-4">
            <div @click="expansions.tiered.winter = !expansions.tiered.winter" class="d-flex flex-row hol-table-header align-center">
              <div><span class="font-weight-bold mr-2">Winter Residential Rates</span>(Nov 1 - Apr 30)</div>
              <v-spacer></v-spacer>
              <v-btn @click="expansions.tiered.winter = !expansions.tiered.winter" icon dark><v-icon>{{ expansions.tiered.winter ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon></v-btn>
            </div>
            <div v-if="expansions.tiered.winter" class="d-flex flex-row hol-table-row">
              <div class="flex-1">Tier 1</div>
              <div class="flex-1">first 1000 kWh</div>
              <div class="flex-1">10.3 ¢/kWh</div>
            </div>
            <div v-if="expansions.tiered.winter" class="d-flex flex-row hol-table-row">
              <div class="flex-1">Tier 2</div>
              <div class="flex-1">above 1000 kWh</div>
              <div class="flex-1">12.5 ¢/kWh</div>
            </div>
          </div>
          <div class="hol-table d-flex flex-column my-4">
            <div @click="expansions.tiered.summer = !expansions.tiered.summer" class="d-flex flex-row hol-table-header align-center">
              <div><span class="font-weight-bold mr-2">Summer Residential Rates</span>(May 1 to Oct 31)</div>
              <v-spacer></v-spacer>
              <v-btn @click="expansions.tiered.summer = !expansions.tiered.summer" icon dark><v-icon>{{ expansions.tiered.summer ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon></v-btn>
            </div>
            <div v-if="expansions.tiered.summer" class="d-flex flex-row hol-table-row">
              <div class="flex-1">Tier 1</div>
              <div class="flex-1">first 600 kWh</div>
              <div class="flex-1">10.3 ¢/kWh</div>
            </div>
            <div v-if="expansions.tiered.summer" class="d-flex flex-row hol-table-row">
              <div class="flex-1">Tier 2</div>
              <div class="flex-1">above 600 kWh</div>
              <div class="flex-1">12.5 ¢/kWh</div>
            </div>
          </div>
          <div class="text-caption grey--text">The Ontario Energy Board (OEB) set these rates. 
            <a href="https://www.oeb.ca/consumer-information-and-protection/electricity-rates#current" target="_blank">
              Learn more about tiered rates structure and pricing
            </a>.
          </div>
        </div>
        <v-card class="flex-1" flat color="grey lighten-3">
          <div class="overline mt-4">Explainer Graphics here</div>
          <v-img src="@/assets/rpp-graphics-tiered-en.png" aspect-ratio="1.5" contain></v-img>
        </v-card>
      </div>
    </v-card>
    <!-- ---------------------- Time-of-Use Explained ---------------------- -->
    <v-card v-if="selectedPlan == 'tou'" class="ma-2 pa-4">
      <h3 class="mb-4">Time-of-Use Rate Plan</h3>
      <div class="d-flex flex-row mt-4">
        <div class="flex-1 px-4">
          Under the Time-of-use pricing, you pay a different rate for electricity depending on the time of day and day of the week you consume it. Time-of-use has three periods: on-peak (when energy demand and cost is high), mid-peak (when energy demand and cost is moderate) and off-peak (when energy demand and cost is low).<br>
          <br>
          Time-of-use periods (hours) are different in the summer than they are in the winter.<br>
          <div class="hol-table d-flex flex-column my-4">
            <div @click="expansions.tou.winter = !expansions.tou.winter" class="d-flex flex-row hol-table-header align-center">
              <div><span class="font-weight-bold mr-2">Winter Residential Rates</span>(Nov 1 - Apr 30)</div>
              <v-spacer></v-spacer>
              <v-btn @click="expansions.tou.winter = !expansions.tou.winter" icon dark><v-icon>{{ expansions.tou.winter ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon></v-btn>
            </div>
            <div v-if="expansions.tou.winter" class="d-flex flex-row hol-table-row">
              <div class="flex-1">Off-Peak</div>
              <div class="flex-2">
                Weekdays 7 p.m. to 7 a.m.<br>
                Weekends and holidays all day
              </div>
              <div class="flex-1">8.7 ¢/kWh</div>
            </div>
            <div v-if="expansions.tou.winter" class="d-flex flex-row hol-table-row">
              <div class="flex-1">Mid-Peak</div>
              <div class="flex-2">
                Weekdays 11 a.m. to 5 p.m.
              </div>
              <div class="flex-1">12.2 ¢/kWh</div>
            </div>
            <div v-if="expansions.tou.winter" class="d-flex flex-row hol-table-row hol-table-row-last">
              <div class="flex-1">On-Peak</div>
              <div class="flex-2">
                Weekdays 7 a.m. to 11 a.m. and<br>
                Weekdays 5 p.m. to 7 p.m.
              </div>
              <div class="flex-1">18.2 ¢/kWh</div>
            </div>
          </div>
          <div class="hol-table d-flex flex-column my-4">
            <div @click="expansions.tou.summer = !expansions.tou.summer" class="d-flex flex-row hol-table-header align-center">
              <div><span class="font-weight-bold mr-2">Summer Residential Rates</span>(May 1 to Oct 31)</div>
              <v-spacer></v-spacer>
              <v-btn icon dark @click="expansions.tou.summer = !expansions.tou.summer"><v-icon>{{ expansions.tou.summer ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon></v-btn>
            </div>
            <div v-if="expansions.tou.summer" class="d-flex flex-row hol-table-row">
              <div class="flex-1">Off-Peak</div>
              <div class="flex-2">
                Weekdays 7 p.m. to 7 a.m.<br>
                Weekends and holidays all day
              </div>
              <div class="flex-1">8.7 ¢/kWh</div>
            </div>
            <div v-if="expansions.tou.summer" class="d-flex flex-row hol-table-row">
              <div class="flex-1">Mid-Peak</div>
              <div class="flex-2">
                Weekdays 7 a.m. to 11 a.m. and<br>
                Weekdays 5 p.m. to 7 p.m.
              </div>
              <div class="flex-1">12.2 ¢/kWh</div>
            </div>
            <div v-if="expansions.tou.summer" class="d-flex flex-row hol-table-row hol-table-row-last">
              <div class="flex-1">On-Peak</div>
              <div class="flex-2">
                Weekdays 11 a.m. to 5 p.m.
              </div>
              <div class="flex-1">18.2 ¢/kWh</div>
            </div>
          </div>
          <div class="text-caption grey--text">The Ontario Energy Board (OEB) set these rates. 
            <a href="https://www.oeb.ca/consumer-information-and-protection/electricity-rates#current" target="_blank">
              Learn more about the time-of-use rates and structure
            </a>.
          </div>
        </div>
        <v-card class="flex-1" flat color="grey lighten-3">
          <div class="overline mt-4">Explainer Graphics here</div>
          <v-img src="@/assets/rpp-graphics-tou-en.png" aspect-ratio="1.5" contain></v-img>
        </v-card>
      </div>
    </v-card>
    <!-- ---------------------- Ultra-Low Overnight Explained ---------------------- -->
    <v-card v-if="selectedPlan == 'ulo'" class="ma-2 pa-4">
      <h3 class="mb-4">Ultra-Low Overnight Rate Plan</h3>
      <div class="d-flex flex-row mt-4">
        <div class="flex-1 px-4">
          Similar to Time-of-use rates, with Ultra-low overnight, the price depends on when you use electricity. Ultra-low overnight has an additional price period, a different set of rates and offers an ultra-low overnight rate in exchange for a higher on-peak rate.<br>
          <br>
          With Ultra-low overnight pricing you are charged according to the day of the week and time of day that you use electricity. There are four price periods: on-peak, mid-peak, weekend off-peak, and ultra-low-overnight. The Ultra-low overnight plan sets a very low price for the overnight period, however, the on-peak price is significantly higher.<br>
          <br>
          This plan could be beneficial to those who consume a major portion of their electricity overnight, or to those who charge an electric vehicle overnight.<br>
          <br>
          The Ultra-low overnight periods (hours) are the same in the summer as they are in the winter.
          <div class="hol-table d-flex flex-column my-4">
            <div @click="expansions.ulo = !expansions.ulo" class="d-flex flex-row hol-table-header align-center">
              <div><span class="font-weight-bold mr-2">Current Rates</span>(All year)</div>
              <v-spacer></v-spacer>
              <v-btn @click="expansions.ulo = !expansions.ulo" icon dark><v-icon>{{ expansions.ulo ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon></v-btn>
            </div>
            <div v-if="expansions.ulo" class="d-flex flex-row hol-table-row">
              <div class="flex-1">Ultra-low overnight</div>
              <div class="flex-2">Everyday 11 p.m. to 7 a.m.</div>
              <div class="flex-1">2.8 ¢/kWh</div>
            </div>
            <div v-if="expansions.ulo" class="d-flex flex-row hol-table-row">
              <div class="flex-1">Weekend off-peak</div>
              <div class="flex-2">Weekends and holidays 7 a.m. to 11 p.m.</div>
              <div class="flex-1">8.7 ¢/kWh</div>
            </div>
            <div v-if="expansions.ulo" class="d-flex flex-row hol-table-row">
              <div class="flex-1">Mid-peak</div>
              <div class="flex-2">
                Weekdays 7 a.m. to 4 p.m. and<br>
                Weekdays 9 p.m. to 11 p.m.
              </div>
              <div class="flex-1">12.2 ¢/kWh</div>
            </div>
            <div v-if="expansions.ulo" class="d-flex flex-row hol-table-row">
              <div class="flex-1">On-peak</div>
              <div class="flex-2">Weekdays 4 p.m. to 9 p.m.</div>
              <div class="flex-1">28.6 ¢/kWh</div>
            </div>
          </div>
          <div class="text-caption grey--text">The Ontario Energy Board (OEB) set these rates. 
            <a href="https://www.oeb.ca/consumer-information-and-protection/electricity-rates#current" target="_blank">
              Learn more about the Ultra-low overnight rates and structure
            </a>.
          </div>
        </div>
        <v-card class="flex-1" flat color="grey lighten-3">
          <div class="overline mt-4">Explainer Graphics here</div>
          <v-img src="@/assets/rpp-graphics-ulo-en.png" aspect-ratio="1.5" contain></v-img>
        </v-card>
      </div>
    </v-card>
  </div>
</template>
<script>
export default {
  name: 'RateExplorerStep1',
  props: ['data'],
  data() {
    return {
      step: 1,
      selectedPlan: '',
      expansions: {
        tiered: {
          winter: false,
          summer: false
        },
        tou: {
          winter: false,
          summer: false
        },
        ulo: false,
      }
    }
  },
  methods: {
    getPlanName(code, lang) {
      if (!code) return 'n/a'
      if (code == 'ulo') return 'Ultra-Low Overnight'
      if (code == 'tou') return 'Time-of-Use'
      if (code == 'tiered') return 'Tiered'
    },
  },
  filters: {
    currency(value) {
      return '$' + value.toFixed(2)
    }
  }
}
</script>
<style lang="scss" scoped>
  .hol-table {
    // border: 1px solid grey;
    .hol-table-header {
      background: #005694;
      color: white;
      border: 1px solid #004679;
      padding: 10px;
    }
    .hol-table-header:hover {
      cursor: pointer;
    }
    .hol-table-row {
      border: 1px solid #d0dde6;
      border-bottom: none;
      padding: 10px;
      &:nth-child(odd) {
        background: #dae6f0;
        border-bottom: 1px solid #d0dde6;
      }
    }
    .hol-table-row-last {
      border-bottom: 1px solid #d0dde6;
    }
  }
  .cost-banner-green {
    background-color: #edf7ee;
  }
  .cost-banner-red {
    background-color: #feeceb;
  }
  .cost-banner-grey {
    background-color: #f7f7f7;
  }
</style>