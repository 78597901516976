<template>
  <v-container>
    <h1 class="mb-4">Rate Application</h1>
    <v-card class="text-left">
      <v-card-title>
        Intelligent Assistant
        <v-spacer></v-spacer>
        <!-- <RateApplicationSearch :user="user"></RateApplicationSearch> -->
        <v-btn icon @click="startConversation()"><v-icon>mdi-refresh</v-icon></v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <div v-if="initializing" class="text-center pa-12">
        <div id="loader">
          <div id="sisyphus">
            <div id="box"></div>
            <div id="hill"></div>
          </div>
        </div>
        <div class="text-caption pt-4">
          Initializing Agent...
        </div>
      </div>
      <v-card-text v-else>
        <v-alert type="info" outlined>
          <strong>Early Preview:</strong> You are able to search, summarize, and generate answers. You can also ask follow-up questions. Data is based on Hydro Ottawa's 2021 - 2025 Rate Application (without interrogatories). Please be sure to rate the response you receive.
        </v-alert>
        <!-- Query Field -->
        <!-- <v-text-field v-model="userQuery" filled rounded @keyup.enter="sendQuery" prepend-inner-icon="mdi-star-four-points" append-outer-icon="mdi-send" @click:append-outer="sendQuery" :disabled="loading"></v-text-field> -->
        <v-text-field v-model="userQuery" filled rounded @keyup.enter="sendQuery" prepend-inner-icon="mdi-creation" append-outer-icon="mdi-send" @click:append-outer="sendQuery" :disabled="loading">
          <template v-slot:append>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on">mdi-dots-vertical</v-icon>
              </template>
              <v-list>
                <v-list-item v-for="(prompt, index) in prompts" :key="'prompt' + index" @click="userQuery = prompt.title">
                  <v-list-item-subtitle class="ellispis">[<span :class="prompt.class">{{ prompt.type }}</span>] {{ prompt.title | truncate }}</v-list-item-subtitle>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-text-field>
        <v-divider></v-divider>
        <!-- Message History -->
        <div class="message-history">
          <div v-if="loading" class="text-center pa-4">
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
          </div>
          <v-slide-x-transition group class="d-flex flex-column-reverse">
            <div v-for="(message, index) in history" :key="index" class="message d-flex" :class="message.type == 'user' ? 'justify-end' : 'justify-start'">
              <div v-if="message.type === 'user'" class="user-message pa-4 ma-2">
                {{ message.content }}
              </div>
              <div v-else>
                <div class="system-message pa-6 ma-2" v-html="message.content"></div>
                <div v-for="(reference, refIndex) in message.citations" :key="'ref-' + index + '-' + refIndex" class="system-resources ml-2">
                  <div v-if="reference.sources[0]?.referenceIndex" class="text-caption">[{{ refIndex+1 }}] {{ message.references[reference.sources[0]?.referenceIndex].title }} ({{ reference.startIndex }} - {{ reference.endIndex }}) <!-- {{ message.references[reference.sources[0]?.referenceIndex].uri }} --></div>
                  <div v-else class="text-caption">[{{ refIndex+1 }}]</div>
                </div>
              </div>
            </div>
          </v-slide-x-transition>
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import RateApplicationSearch from '@/components/RateApplicationSearch.vue';
import axios from 'axios';
import { marked } from 'marked';
export default ({
  name: 'RateApplicationAgent',
  props: ['user'],
  components: {
    RateApplicationSearch
  },
  data: () => ({
    userQuery: "",
    history: [],
    conversation: {},
    conversationData: {},
    accessToken: "",
    loading: false,
    initializing: true,
    prompts: [
      { type: 'generate', class: "blue--text", title: "Create an outline for an update to Hydro Ottawa's Conservation and Demand Management (“CDM”) Program including an outline of updates based on Hydro Ottawa's previous plan." },
      { type: 'generate', class: "blue--text", title: "Create a detailed outline for a progress report to Hydro Ottawa's operational maintenance program." },
      { type: 'generate', class: "blue--text", title: "Create an outline for a progress report to Hydro Ottawa's workforce staffing and compensation plan." },
      { type: 'generate', class: "blue--text", title: "Update Hydro Ottawa's Digital strategy assuming a new ERP system and expanded customer functionality and optionality." },
      { type: 'generate', class: "blue--text", title: "Create a list of schedules that discuss maintenance activities (top right header Exhibit, Tab Schedule, so the Schedule is a list of these, for Example Customer Engagement Overview is Schedule 1-2-1)." },
      { type: 'summarize', class: "purple--text", title: "Create a summary of Hydro Ottawa's commitments in the Settlement Agreement." },
      { type: 'search', class: "green--text", title: "What directives and or reporting requirements did the Ontario Energy Board (“OEB”) give Hydro Ottawa to complete in it's Decision and Order? " },
      { type: 'search', class: "green--text", title: "What commitments and reporting did Hydro Ottawa make?" },
      { type: 'search', class: "green--text", title: "What customer engagement commitments did Hydro Ottawa make?" },
    ],
  }), 
  created() {
    this.startConversation()
  },
  methods: {
    async sendQuery() {
      this.loading = true;
      // ------------ Start the Conversation ------------
      if (!this.conversationData.name || !this.accessToken) {
        await this.startConversation();
      }
      // ------------ Add Message to History ------------
      this.history.push({
        id: new Date().getTime(),
        type: 'user',
        content: this.userQuery,
        citations: [],
        references: []
      });
      // ------------ Send the User Query ------------
      const data = {
        query: { input: this.userQuery },
        summarySpec: {
          summaryResultCount: 5,
          modelPromptSpec: {
            preamble: "Given the conversation between a user and a helpful assistant and some search results, create a final answer for the assistant. The answer should use all relevant information from the search results, not introduce any additional information, and use exactly the same words as the search results when possible. The assistant's answer should be no more than 20 sentences. The user is an expert who has an in-depth understanding of the subject matter. The assistant should answer in a technical manner that uses specialized knowledge and terminology when it helps answer the query."
          },
          modelSpec: {
            version: "preview"
          },
          ignoreAdversarialQuery: true,
          includeCitations: true
        }
      };
      const endpoint = 'https://us-discoveryengine.googleapis.com/v1alpha/' + this.conversationData.name + ':converse';
      const response = await axios.post(endpoint, data, {
        headers: {
          'Authorization': `Bearer ${this.accessToken}`,
          'Content-Type': 'application/json'
        }
      }).catch(error => {
        console.error(error)
      });
      this.conversation = response?.data
      // ------------ Check Response ------------
      // The request might have failed so we ought to try it again.
      // TODO: Handle empty responses
      if (!response || !response.data || !response.data.reply) {
        console.error('No response. Please try again.')
        this.loading = false
        return
      }
      // ------------ Add Response to History ------------
      console.log('response', response.data)
      this.history.push({
        id: new Date().getTime(),
        type: 'system',
        content: this.formatMarkdown(response.data.reply.summary.summaryText),
        citations: response.data.reply.summary.summaryWithMetadata.citationMetadata?.citations,
        references: response.data.reply.summary.summaryWithMetadata.references
      });
      //
      this.userQuery = ''; // Clear input after sending
      this.loading = false
    },
    async startConversation() {
      // console.log('start')
      if (this.user) {
        // console.log('start user', this.user)
        // ------------ Clear the Conversation ------------
        this.conversation = {};
        this.history = [];
        this.conversationData = {};
        // ------------ Fetch the Credentials ------------
        let endpoint = "https://us-central1-hydro-ottawa-ai.cloudfunctions.net/getAccessToken"
        let tokenResponse = await axios.get(endpoint)
        this.accessToken = tokenResponse.data.accessToken.token
        const response = await axios.post(`https://us-discoveryengine.googleapis.com/v1alpha/projects/125472739614/locations/us/collections/default_collection/dataStores/hydro-ottawa-rate-application-2021-2025-dp/conversations`, {
          user_pseudo_id: this.user.uid  // Generate or use a method to track user uniquely
        }, {
          headers: {
            'Authorization': `Bearer ${this.accessToken}`,
            'Content-Type': 'application/json'
          }
        });
        this.conversationData = response.data;
        this.initializing = false;
      }
    },
    formatMarkdown(text) {
      if (!text) return 'No response. Please try again.';
      return marked.parse(text); // Convert Markdown to HTML
    },
  },
  filters: {
    truncate(string) {
      let value = 80
      return string?.length > value ? string.slice(0, value) + "..." : string;
    },
  },
  watch: {
    user() {
      this.startConversation()
    },
    $route(to, from) {
      this.startConversation()
    }
  }
})
</script>
<style lang="scss">
.message {
  .system-message {
    max-width: 80%;
    background: #f0f0f0;
    color: black;
    border-radius: 10px;
  }
  .user-message {
    max-width: 80%;
    background: #4caf50;
    color: white;
    border-radius: 10px;
  }
}
#loader {
  position: relative;
  width: 50px;
  height: 50px;
  display: block;
  margin: 20px auto;
  #sisyphus {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -2.7em;
    margin-left: -2.7em;
    width: 5.4em;
    height: 5.4em;
  }

  #hill {
    position: absolute;
    width: 7.1em;
    height: 7.1em;
    top: 1.7em;
    left: 1.7em;
    background-color: transparent;
    border-left: .25em solid #404456;
    transform: rotate(45deg);
  }

  #hill:after {
    content: '';
    position: absolute;
    width: 7.1em;
    height: 7.1em;
    left: 0;
  }

  #box {
    position: absolute;
    left: 0;
    bottom: -.1em;
    width: 1em;
    height: 1em;
    background-color: transparent;
    border: .25em solid #404456;
    border-radius: 15%;
    transform: translate(0, -1em) rotate(-45deg);
    animation: push 2.5s cubic-bezier(.79, 0, .47, .97) infinite;
  }

  @keyframes push {
    0% {
      transform: translate(0, -1em) rotate(-45deg);
    }
    5% {
      transform: translate(0, -1em) rotate(-50deg);
    }
    20% {
      transform: translate(1em, -2em) rotate(47deg);
    }
    25% {
      transform: translate(1em, -2em) rotate(45deg);
    }
    30% {
      transform: translate(1em, -2em) rotate(40deg);
    }
    45% {
      transform: translate(2em, -3em) rotate(137deg);
    }
    50% {
      transform: translate(2em, -3em) rotate(135deg);
    }
    55% {
      transform: translate(2em, -3em) rotate(130deg);
    }
    70% {
      transform: translate(3em, -4em) rotate(217deg);
    }
    75% {
      transform: translate(3em, -4em) rotate(220deg);
    }
    100% {
      transform: translate(0, -1em) rotate(-225deg);
    }
  }
}
</style>