<template>
  <v-container>
    <h1 class="mb-4">Switch Orders Sandbox</h1>
    <v-card>
      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-header>Extract Pages</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-card>
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <v-file-input
                      label="Select file"
                      v-model="selectedFile"
                      :disabled="uploading"
                    ></v-file-input>
                  </v-col>
                  <v-col cols="12">
                    <v-btn
                      :disabled="!selectedFile || uploading"
                      @click="uploadFile"
                    >
                      Upload File
                    </v-btn>
                  </v-col>
                  <v-col cols="12" v-if="uploading">
                    <v-progress-linear
                      :value="uploadProgress"
                      color="blue"
                      height="10"
                      striped
                    ></v-progress-linear>
                  </v-col>
                  <v-col cols="12" v-if="imageUrls.length > 0">
                    <h2>Extracted Pages</h2>
                    <v-row>
                      <v-col
                        v-for="(url, index) in imageUrls"
                        :key="index"
                        cols="4"
                      >
                        <v-img :src="url" aspect-ratio="1"></v-img>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>
  </v-container>
</template>

<script>
import { storage } from "@/firebase/init";
import { ref, uploadBytesResumable } from "firebase/storage";
import { getFirestore, doc, onSnapshot } from "firebase/firestore";

export default {
  name: "OperationsSwitchOrders",
  data() {
    return {
      uploading: false,
      uploadProgress: 0,
      selectedFile: null,
      imageUrls: [],
      docId: null,
    };
  },
  watch: {
    docId(newDocId) {
      if (newDocId) {
        this.setupFirestoreListener(newDocId);
      }
    },
  },
  methods: {
    extractWorkId(filename) {
      const match = filename.match(/Job (\d+)/);
      return match ? match[1] : null;
    },
    async uploadFile() {
      const file = this.selectedFile;
      if (!file) return;

      // Extract the work ID from the file name
      const workId = this.extractWorkId(file.name);
      if (!workId) {
        console.error("Invalid file name. Could not extract work ID.");
        return;
      }

      // Specify the directory inside the bucket
      const directory = `switchorders/${workId}`;
      const fileName = "original.pdf";
      const storageRef = ref(storage, `${directory}/${fileName}`);

      // Create the file upload task
      const uploadTask = uploadBytesResumable(storageRef, file);

      // Update progress bar
      this.uploading = true;
      this.uploadProgress = 0;
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          this.uploadProgress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        (error) => {
          console.error("Error uploading file: ", error);
          this.uploading = false;
        },
        async () => {
          this.uploading = false;
          console.log("File uploaded successfully");

          // Trigger the HTTP Firebase Function after upload
          try {
            const response = await fetch(
              "https://northamerica-northeast1-hydro-ottawa-ai.cloudfunctions.net/processSwitchOrder",
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({ workId }),
              },
            );
            const result = await response.json();
            this.imageUrls = result.imageUrls;
            this.docId = result.docId; // Set docId to start listening for changes
            console.log("Processing triggered successfully");
          } catch (error) {
            console.error("Error calling function: ", error);
          }
        },
      );
    },
    setupFirestoreListener(docId) {
      const firestore = getFirestore();
      const docRef = doc(firestore, "switch_orders", docId);
      onSnapshot(docRef, (doc) => {
        if (doc.exists()) {
          const data = doc.data();
          this.imageUrls = data.imageUrls;
          console.log("Document data:", data);
        } else {
          console.log("No such document!");
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
