<template>
  <v-sheet rounded :color="colour">
    <!-- Title -->
    <div v-if="type == 'title'" class="pa-4 mb-12">
      <div class="text-h6 text-center ml-12">
        {{ content }}
      </div>
    </div>
    <!-- Heading -->
    <div v-if="type == 'heading'" class="mb-3">
      <h3>{{ content }}</h3>
    </div>
    <!-- Text -->
    <div v-if="type == 'text'" class="mb-12" style="font-size: 16px">
      <p>{{ content }}</p>
    </div>
    <!-- List -->
    <div v-if="type == 'list'" class="mb-12">
      <v-list :color="colour">
        <v-list-item-group>
          <v-list-item v-for="item in content" :key="'list'+item">
            <v-list-item-title><v-icon left color="black">mdi-circle-small</v-icon>{{ item }}</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </div>
    
    <div v-if="type == 'card'">
      
    </div>
  </v-sheet>
</template>
<script>
export default {
  name: 'Content',
  props: ['type','colour','content'],
}
</script>