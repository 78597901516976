<template>
    <v-container>
      <h1 class="mb-4">Agent Assist</h1>
      <v-card>
        <v-card-title>Information Search</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-alert type="info">
            This tool will allow the agent to quickly access information to provide the customer while on a call.
          </v-alert>
        </v-card-text>
      </v-card>
    </v-container>
  </template>
<script>
  export default {
    name: 'CustomerServiceAgentAssist',
    data: () => ({
      tab: null,
    }),
  }
</script>