<template>
  <div>
    <div ref="widgetContainer"></div> <!-- Container for the widget -->
    <v-btn color="primary" @click="initializeWidget">Search</v-btn>
  </div>
</template>


<script>
export default {
  name: 'WebSearch',
  mounted() {
    this.loadWidgetScript();
  },
  methods: {
    loadWidgetScript() {
      // Load the script dynamically if it's not already loaded
      const scriptId = 'gen-widget-script';

      if (document.getElementById(scriptId)) return;

      const script = document.createElement('script');
      script.id = scriptId;
      script.src = "https://cloud.google.com/ai/gen-app-builder/client?hl=en_US";
      script.onload = this.initWidget;
      document.head.appendChild(script);
    },

    initWidget() {
      // Initialize the widget after the script is loaded
      const widget = document.createElement('gen-search-widget');
      widget.setAttribute('configId', '884e6618-409c-4572-b225-85c57ad34d89');
      widget.setAttribute('location', 'us');
      widget.setAttribute('triggerId', 'searchWidgetTrigger');
      this.$refs.widgetContainer.appendChild(widget);
    },

    initializeWidget() {
      // Simulate click to open the widget
      const trigger = document.createElement('input');
      trigger.id = 'searchWidgetTrigger';
      trigger.style.display = 'none'; // Hide it since it's not needed visibly
      document.body.appendChild(trigger);
      trigger.click();
      document.body.removeChild(trigger);
    }
  }
}
</script>
