import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFunctions } from "firebase/functions";

const firebaseConfig = {
  apiKey: "AIzaSyAnu00yGFU7Gqtu5_U_x0Y5DVDe_3SbPB4",
  authDomain: "hydroottawa.ai",
  projectId: "hydro-ottawa-ai",
  storageBucket: "hydro-ottawa-ai.appspot.com",
  messagingSenderId: "125472739614",
  appId: "1:125472739614:web:d3329dad8341bd048db90f",
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const storage = getStorage(app);
const functions = getFunctions(app);

export { app, analytics, auth, storage, functions };
