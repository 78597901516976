<template>
  <v-container>
    <h1 class="mb-4">Meet the Team</h1>
    <v-card>
      <!-- <v-row class="pa-2">
        <v-col v-for="person in sorted" :key="person.name" cols="12" xs="12" sm="6" md="3">
          <v-card class="mx-auto">
            <v-img class="white--text align-end" height="200px" :src="person.photo"></v-img>
            <v-divider></v-divider>
            <v-card-title>
              {{ person.name }}
              <v-spacer></v-spacer>
              <v-icon v-if="person.company === 'Google'">mdi-google</v-icon>
            </v-card-title>
            <v-card-subtitle class="text-left">
              {{ person.role }}
            </v-card-subtitle>
            <v-card-text class="text--primary">
              {{ person.description }}
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-divider class="my-6"></v-divider> -->
      <v-card-text>
        <h2 class="mb-6">Hydro Ottawa</h2>
        <v-row class="pa-2">
          <v-col v-for="person in hydroottawa" :key="person.name" cols="12" xs="12" sm="6" md="3">
            <v-card class="mx-auto">
              <v-img
                class="white--text align-end"
                height="200px"
                :src="person.photo"
              >
              </v-img>
              <v-divider></v-divider>
              <v-card-title>
                {{ person.name }}
                <v-spacer></v-spacer>
                <v-icon v-if="person.company === 'Google'">mdi-google</v-icon>
              </v-card-title>
              <v-card-subtitle class="text-left">
                {{ person.role }}
              </v-card-subtitle>
              <v-card-text class="text--primary">
                {{ person.description }}
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-divider class="my-6"></v-divider>
        <h2 class="mb-6">Google</h2>
        <v-row class="pa-2">
          <v-col v-for="person in google" :key="person.name" cols="12" xs="12" sm="6" md="3">
            <v-card class="mx-auto">
              <v-img
                class="white--text align-end"
                height="200px"
                :src="person.photo"
              >
              </v-img>
              <v-divider></v-divider>
              <v-card-title>
                {{ person.name }}
                <v-spacer></v-spacer>
                <v-icon v-if="person.company === 'Google'">mdi-google</v-icon>
              </v-card-title>
              <v-card-subtitle class="text-left">
                {{ person.role }}
              </v-card-subtitle>
              <v-card-text class="text--primary">
                {{ person.description }}
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
export default ({
  name: 'Team',
  data: () => ({
    people: [
      {
        name: 'Aaron Farr',
        company: 'Hydro Ottawa',
        role: '',
        photo: 'https://via.placeholder.com/200',
        description: ''
      },
      {
        name: 'Mark Fernandes',
        company: 'Hydro Ottawa',
        role: '',
        photo: 'https://via.placeholder.com/200',
        description: ''
      },
      {
        name: 'Andrew Willis',
        company: 'Hydro Ottawa',
        role: '',
        photo: 'https://via.placeholder.com/200',
        description: ''
      },
      {
        name: 'Harold Fudge',
        company: 'Hydro Ottawa',
        role: '',
        photo: 'https://via.placeholder.com/200',
        description: ''
      },
      {
        name: 'Santhosh Jayasankar',
        company: 'Hydro Ottawa',
        role: '',
        photo: 'https://via.placeholder.com/200',
        description: ''
      },
      {
        name: 'Seb Oran',
        company: 'Hydro Ottawa',
        role: '',
        photo: 'https://via.placeholder.com/200',
        description: ''
      },
      {
        name: 'Shervin Sheidaei',
        company: 'Hydro Ottawa',
        role: '',
        photo: 'https://via.placeholder.com/200',
        description: ''
      },
      // {
      //   name: 'Guillaume Chretien',
      //   company: 'Hydro Ottawa',
      //   role: '',
      //   photo: 'https://via.placeholder.com/200',
      //   description: ''
      // },
      {
        name: 'Aditya Dhekney',
        company: 'Google',
        role: '',
        photo: 'https://via.placeholder.com/200',
        description: ''
      },
      {
        name: 'Cindy Zhong',
        company: 'Google',
        role: '',
        photo: 'https://via.placeholder.com/200',
        description: ''
      },
      {
        name: 'Emmanuel Pacheco',
        company: 'Google',
        role: '',
        photo: 'https://via.placeholder.com/200',
        description: ''
      },
      {
        name: 'Diana John',
        company: 'Google',
        role: '',
        photo: 'https://via.placeholder.com/200',
        description: ''
      },
      {
        name: 'Yonina Zlotnick',
        company: 'Google',
        role: '',
        photo: 'https://via.placeholder.com/200',
        description: ''
      },
      {
        name: 'Rihana Msadek',
        company: 'Google',
        role: '',
        photo: 'https://via.placeholder.com/200',
      }
    ]
  }),
  computed: {
    hydroottawa() {
      // Filter for Hydro Ottawa employees and sort the result
      return this.people
        .filter(person => person.company === 'Hydro Ottawa')
        .sort((a, b) => a.name.localeCompare(b.name))
    },
    google() {
      return this.people
        .filter(person => person.company === 'Google')
        .sort((a, b) => a.name.localeCompare(b.name))
    },
    sorted() {
      return this.people.sort((a, b) => a.name.localeCompare(b.name))
    }
  }
})
</script>
<style lang="scss">

</style>