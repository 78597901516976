<template>
  <div class="d-flex flex-column align-center justify-center" style="height: 100%">
    <div class="icon mb-6" @click="colour = 'black'">
      <v-icon color="black" x-large>mdi-lightning-bolt</v-icon>
    </div>
    <h2>404 NOT FOUND</h2>
    <p>The requested resource does not exist</p>
    <div class="key">
      <v-btn icon @click="googleAuth()" :loading="authenticating">
        <v-icon :color="colour">mdi-key</v-icon>
      </v-btn>
    </div>
  </div>
</template>
<script>
import { getAuth, signInWithPopup, signInWithRedirect, GoogleAuthProvider } from 'firebase/auth'
export default ({
  name: 'Landing',
  data() {
    return {
      authenticating: false,
      colour: 'white',
      year: new Date().getFullYear()
    }
  },
  methods: {
    googleAuth() {
      this.authenticating = true
      let provider = new GoogleAuthProvider()
      let auth = getAuth()
      provider.addScope('email')
      provider.addScope('profile')
      provider.addScope('https://www.googleapis.com/auth/userinfo.email')
      provider.addScope('https://www.googleapis.com/auth/userinfo.profile')
      // if local use signInWithPopup, otherwise use signInWithRedirect
      let loginMethod = location.hostname === 'localhost' ? signInWithPopup(auth, provider) : signInWithRedirect(auth, provider)
      loginMethod.then( credentials => {
        // Don't have to redirect as App will handle that
      }).catch( error => {
        if (error.code == 'auth/popup-closed-by-user') {
          console.log('Google authentication popup closed.')
        } else {
          console.log(error.message)
        }
        this.authenticating = false
      })
    }
  }
})
</script>
<style lang="scss">
  .icon {
    padding: 20px;
    border-radius: 44px;
    border: 4px solid black;
  }
  .key {
    position: absolute;
    bottom: 10px;
    right: 10px;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>