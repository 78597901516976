<template>
  <div>
    <div class="text-h5">Comparing Plans</div>
    <div class="py-4">
      Using your consumption data we can compare your current rate plan to other rate plans to see if you can save money.
    </div>
    <!-- ------------ Headers ------------ -->
    <!-- <div class="d-flex flex-row mb-1">
      <div class="flex-1 font-italic text-caption">Your current plan</div>
      <div class="ml-2 flex-2 d-flex flex-row align-center">
        <div class="flex-1" style="border-bottom: 1px solid #ccc"></div>
        <div class="mx-4 font-italic text-caption">Alternative Plans</div>
        <div class="flex-1" style="border-bottom: 1px solid #ccc"></div>
      </div>
    </div> -->
    <!-- ------------ Plan Blocks ------------ -->
    <div v-if="false" class="d-flex flex-row mb-4">
      <div class="flex-1 pr-1">
        <!-- ------------ Current Plan ------------ -->
        <v-sheet @click="comparisonPlan = ''" class="pa-4 d-flex flex-column justify-center" rounded elevation="2" height="100%">
          <div><strong>{{ currentPlanName }} Rates</strong></div>
          <div class="mt-2 font-italic">
            You currently pay an average of {{ data.cost[data.plan.current].total.sum | currency }} per month*
          </div>
        </v-sheet>
      </div>
      <!-- ------------ TOU Plan ------------ -->
      <div v-if="data.plan.current != 'tou'" class="flex-1 pl-1">
        <v-sheet @click="comparisonPlan = 'tou'" class="pa-4" :dark="comparisonPlan == 'tou'" :color="comparisonPlan == 'tou' ? '#005c9b' : '#f5f5f5'" rounded  height="100%">
          <v-btn icon v-if="comparisonPlan == 'tou'"><v-icon>mdi-circle-slice-8</v-icon></v-btn>
          <v-btn icon v-else><v-icon>mdi-circle-outline</v-icon></v-btn>
          <div class="font-weight-bold">Time-of-Use</div>
          <div class="mt-2 font-italic">You would pay an average of {{ data.cost.tou.total.sum | currency }} per month*</div>
          <div class="font-weight-bold green--text" v-if="data.cost.tou.total.sum < data.cost[data.plan.current].total.sum">
            Savings of {{ data.cost[data.plan.current].total.sum - data.cost.tou.total.sum | currency }}/month
          </div>
          <div class="font-weight-bold red--text" v-else>
            Increase of {{ data.cost.tou.total.sum - data.cost[data.plan.current].total.sum | currency }}/month
          </div>
        </v-sheet>
      </div>
      <!-- ------------ Tiered Plan ------------ -->
      <div v-if="data.plan.current != 'tiered'" class="flex-1 pl-1">
        <v-sheet @click="comparisonPlan = 'tiered'" class="pa-4" :dark="comparisonPlan == 'tiered'" :color="comparisonPlan == 'tiered' ? '#005c9b' : '#f5f5f5'" rounded  height="100%">
          <v-btn icon v-if="comparisonPlan == 'tiered'"><v-icon>mdi-circle-slice-8</v-icon></v-btn>
          <v-btn icon v-else><v-icon>mdi-circle-outline</v-icon></v-btn>
          <div class="font-weight-bold">Tiered</div>
          <div class="mt-2 font-italic">You would pay an average of {{ data.cost.tiered.total.sum | currency }} per month*</div>
          <div class="font-weight-bold green--text" v-if="data.cost.tiered.total.sum < data.cost[data.plan.current].total.sum">
            Savings of {{ data.cost[data.plan.current].total.sum - data.cost.tiered.total.sum | currency }}/month
          </div>
          <div class="font-weight-bold red--text" v-else>
            Increase of {{ data.cost.tiered.total.sum - data.cost[data.plan.current].total.sum | currency }}/month
          </div>
        </v-sheet>
      </div>
      <!-- ------------ ULO Plan ------------ -->
      <div v-if="data.plan.current != 'ulo'" class="flex-1 pl-1">
        <v-sheet @click="comparisonPlan = 'ulo'" class="pa-4" :dark="comparisonPlan == 'ulo'" :color="comparisonPlan == 'ulo' ? '#005c9b' : '#f5f5f5'" rounded height="100%">
          <v-btn icon v-if="comparisonPlan == 'ulo'"><v-icon>mdi-circle-slice-8</v-icon></v-btn>
          <v-btn icon v-else><v-icon>mdi-circle-outline</v-icon></v-btn>
          <div class="font-weight-bold">Ultra Low Overnight</div>
          <div class="mt-2 font-italic">You would pay an average of {{ data.cost.ulo.total.sum | currency }} per month*</div>
          <div class="font-weight-bold green--text" v-if="data.cost.ulo.total.sum < data.cost[data.plan.current].total.sum">
            Savings of {{ data.cost[data.plan.current].total.sum - data.cost.ulo.total.sum | currency }}/month
          </div>
          <div class="font-weight-bold red--text" v-else>
            Increase of {{ data.cost.ulo.total.sum - data.cost[data.plan.current].total.sum | currency }}/month
          </div>
        </v-sheet>
      </div>
    </div>
    <!-- ---------------------- Graph Row ---------------------- -->
    <div class="d-flex flex-column flex-md-row">
      <v-sheet class="flex-2">
        <h3 v-if="data.plan.current == 'tou' && !comparisonPlan" class="my-4">Your Current Time-of-Use Rates</h3>
        <h3 v-if="data.plan.current == 'tiered' && !comparisonPlan" class="my-4">Your Current Tiered Rates</h3>
        <h3 v-if="data.plan.current == 'ulo' && !comparisonPlan" class="my-4">Your Current Ultra-Low Overnight Rates</h3>
                
        <h3 v-if="data.plan.current && comparisonPlan" class="my-4">
          Current {{ currentPlanName }} Rate Plan vs. {{ comparisonPlanName }} Rate Plan*
        </h3> 
        
        <v-btn-toggle
          v-model="display"
          tile
          color="#005794"
          group
        >
          <v-btn value="cost">
            By Cost
          </v-btn>
          <v-btn value="consumption">
            By Consumption
          </v-btn>
          <v-btn value="shift">
            With Shift*
          </v-btn>
        </v-btn-toggle>
        
        <!-- ------------ Graph ------------ -->
        <div class="d-flex flex-row mr-4" style="height: 400px;">
          <div class="d-flex flex-column">
            <div v-if="comparisonPlan" class="icon">
              <v-icon x-large color="white">mdi-minus-circle</v-icon>
            </div>
            <div class="flex-1 d-flex flex-column y-axis">
              <div class="flex-1 d-flex flex-row align-center justify-end">
                <div class="mr-1">10</div>
                <div class="axis-marker"></div>
              </div>
              <div class="flex-1 d-flex flex-row align-center justify-end">
                <div class="mr-1">4</div>
                <div class="axis-marker"></div>
              </div>
              <div class="flex-1 d-flex flex-row align-center justify-end">
                <div class="mr-1">3</div>
                <div class="axis-marker"></div>
              </div>
              <div class="flex-1 d-flex flex-row align-center justify-end">
                <div class="mr-1">2</div>
                <div class="axis-marker"></div>
              </div>
              <div class="flex-1 d-flex flex-row align-center justify-end">
                <div class="mr-1">1</div>
                <div class="axis-marker"></div>
              </div>
            </div>
            <div class="white--text">x</div>
          </div>
          <!-- ------ Bar ------ -->
          <div class="flex-1 d-flex flex-column" v-for="(month, index) in data.sampleData" :key="'month'+index">
            <div v-if="comparisonPlan" class="icon">
              <v-icon v-if="month[data.plan.current] < month[comparisonPlan]"  color="#D73A0F" large>mdi-arrow-up-circle</v-icon>
              <v-icon v-if="month[data.plan.current] == month[comparisonPlan]" large>mdi-minus-circle</v-icon>
              <v-icon v-if="month[data.plan.current] > month[comparisonPlan]"  color="green" large>mdi-arrow-down-circle</v-icon>
            </div>
            <div class="flex-1 d-flex px-1" :class="(data.plan.current == 'tiered' && comparisonPlan == 'tou') || data.plan.current == 'ulo' ? 'flex-row-reverse' : 'flex-row'">
              <!-- TOU Bar -->
              <div v-if="data.plan.current == 'tou' || comparisonPlan == 'tou'" class="rate-bar tou-bar d-flex flex-column justify-end" :class="data.plan.current == 'tou' && comparisonPlan ? 'transparent' :''">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div class="bar-segment tou-on-segment" :style="{height: month.touOn + '%'}" v-bind="attrs" v-on="on"></div>
                  </template>
                  <span>{{ month.touOn }}</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div class="bar-segment tou-mid-segment" :style="{height: month.touMid + '%'}" v-bind="attrs" v-on="on"></div>
                  </template>
                  <span>{{ month.touMid }}</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div class="bar-segment tou-off-segment" :style="{height: month.touOff + '%'}" v-bind="attrs" v-on="on"></div>
                  </template>
                  <span>{{ month.touOff }}</span>
                </v-tooltip>
              </div>
              <!-- Tiered Bar -->
              <div v-if="data.plan.current == 'tiered' || comparisonPlan == 'tiered'" class="rate-bar tiered-bar d-flex flex-column justify-end" :class="data.plan.current == 'tiered' && comparisonPlan ? 'transparent' :''">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div class="bar-segment tiered-tier2-segment" :style="{height: month.tier2 + '%'}" v-bind="attrs" v-on="on"></div>
                  </template>
                  <span>{{ month.tier2 }}</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div class="bar-segment tiered-tier1-segment" :style="{height: month.tier1 + '%'}" v-bind="attrs" v-on="on"></div>
                  </template>
                  <span>{{ month.tier1 }}</span>
                </v-tooltip>
              </div>
              <!-- ULO Bar -->
              <div v-if="data.plan.current == 'ulo' || comparisonPlan == 'ulo'" class="rate-bar ulo-bar d-flex flex-column justify-end" :class="data.plan.current == 'ulo' && comparisonPlan ? 'transparent' :''">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div class="bar-segment ulo-on-segment opacity" :style="{height: month.uloOn + '%'}" v-bind="attrs" v-on="on"></div>
                  </template>
                  <span>{{ month.uloOn }}</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div class="bar-segment ulo-mid-segment opacity" :style="{height: month.uloMid + '%'}" v-bind="attrs" v-on="on"></div>
                  </template>
                  <span>{{ month.uloMid }}</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div class="bar-segment ulo-weekend-segment opacity" :style="{height: month.uloOff + '%'}" v-bind="attrs" v-on="on"></div>
                  </template>
                  <span>{{ month.uloOff }}</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div class="bar-segment ulo-overnight-segment opacity" :style="{height: month.uloOvernight + '%'}" v-bind="attrs" v-on="on"></div>
                  </template>
                  <span>{{ month.uloOvernight }}</span>
                </v-tooltip>
              </div>
            </div>
            <div class="label x-axis font-italic text-caption">{{ labels[index] }}</div>
          </div>
        </div>
        <div class="legend pa-4 ml-4 d-flex flex-row">
          <!-- TOU Legend -->
          <div v-if="data.plan.current == 'tou' || comparisonPlan == 'tou'" class="d-flex flex-row">
            <div class="d-flex flex-row mr-4">
              <div class="swatch tou-on-segment"></div>
              <div class="text-caption">On-Peak</div>
            </div>
            <div class="d-flex flex-row mr-4">
              <div class="swatch tou-mid-segment"></div>
              <div class="text-caption">Mid-Peak</div>
            </div>
            <div class="d-flex flex-row mr-4">
              <div class="swatch tou-off-segment"></div>
              <div class="text-caption">Off-Peak</div>
            </div>
          </div>
          <!-- ULO Legend -->
          <div v-if="data.plan.current == 'ulo' || comparisonPlan == 'ulo'" class="d-flex flex-row">
            <div class="d-flex flex-row mr-4">
              <div class="swatch ulo-on-segment"></div>
              <div class="text-caption">ULO On-Peak</div>
            </div>
            <div class="d-flex flex-row mr-4">
              <div class="swatch ulo-mid-segment"></div>
              <div class="text-caption">ULO Mid-Peak</div>
            </div>
            <div class="d-flex flex-row mr-4">
              <div class="swatch ulo-weekend-segment"></div>
              <div class="text-caption">Weekend Off-Peak</div>
            </div>
            <div class="d-flex flex-row mr-4">
              <div class="swatch ulo-overnight-segment"></div>
              <div class="text-caption">Ultra-Low Overnight</div>
            </div>
          </div>
          <!-- Tiered Legend -->
          <div v-if="data.plan.current == 'tiered' || comparisonPlan == 'tiered'" class="d-flex flex-row">
            <div class="d-flex flex-row mr-4">
              <div class="swatch tiered-tier1-segment"></div>
              <div class="text-caption">Tier 1</div>
            </div>
            <div class="d-flex flex-row mr-4">
              <div class="swatch tiered-tier2-segment"></div>
              <div class="text-caption">Tier 2</div>
            </div>
          </div>
        </div>
      </v-sheet>
      <!-- ------------ Information Box ------------ -->
      <div class="flex-1 d-flex">
        <v-sheet class="pa-4" color="grey lighten-4" rounded>
          <div class="text-caption font-italic">
            Your Current Rate Plan
          </div>
          <h3>{{ currentPlanName }} Rate Plan</h3>

          <div class="mt-2 overline">
            Compared To
          </div>
          <div class="d-flex flex-column">
            <v-btn v-if="data.plan.current != 'tou'" @click="comparisonPlan = 'tou'" block class="mt-2" :color="comparisonPlan == 'tou' ? '#005c9b' : ''" :dark="comparisonPlan == 'tou' ? true : false">Time-of-Use Rates</v-btn>
            <v-btn v-if="data.plan.current != 'tiered'" @click="comparisonPlan = 'tiered'" block class="mt-2" :color="comparisonPlan == 'tiered' ? '#005c9b' : ''" :dark="comparisonPlan == 'tiered' ? true : false">Tiered Rates</v-btn>
            <v-btn v-if="data.plan.current != 'ulo'" @click="comparisonPlan = 'ulo'" block class="mt-2" :color="comparisonPlan == 'ulo' ? '#005c9b' : ''" :dark="comparisonPlan == 'ulo' ? true : false">Ultra-low Overnight</v-btn>
          </div>
          
          <div v-if="data.plan.current == 'tiered' && comparisonPlan == 'tou'" class="py-2">
            Current tiered vs. tou statistics
          </div>
          <div v-if="data.plan.current == 'tiered' && comparisonPlan == 'ulo'" class="py-2">
            Current tiered vs. ulo statistics
          </div>
          <div v-if="data.plan.current == 'tou' && comparisonPlan == 'tiered'" class="py-2">
            Current tou vs. tiered statistics
          </div>
          <div v-if="data.plan.current == 'tou' && comparisonPlan == 'ulo'" class="py-2">
            Current tou vs. ulo statistics
          </div>
          <div v-if="data.plan.current == 'ulo' && comparisonPlan == 'tou'" class="py-2">
            Current ulo vs. tou statistics
          </div>
          <div v-if="data.plan.current == 'ulo' && comparisonPlan == 'tiered'" class="py-2">
            Current ulo vs. tiered statistics
          </div>

          <div v-if="data.plan.current == 'ulo' && comparisonPlan == ''" class="py-2">
            Current ulo statistics
          </div>
          
          <v-sheet class="mt-4 pa-4" rounded>
            <div class="overline">Insights</div>
            <div>
              <strong>Your current plan is cheapest.</strong>
              Since the majority of your consumption is already in the lowest tier you are maximizing its efficiency. Switching to another plan without an adjustment in behaviour would likely result in higher payments.
            </div>
          </v-sheet>
          <v-btn v-if="comparisonPlan" class="mt-4" color="#005c9b" dark block tile>Switch Plan</v-btn>
        </v-sheet>
      </div>
    </div>
    <div class="text-caption font-italic grey--text">*assuming the same consumption as last year</div>
  </div>
</template>
<script>
export default {
  name: 'RateExplorerStep3',
  props: ['data'],
  data() {
    return {
      step: 3,
      comparisonPlan: '',
      display: 'cost',
      labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    }
  },
  methods: {
    nextStep() {
      this.$emit('nextStep', this.step + 1)
    }
  },
  computed: {
    currentPlanName() {
      if (!this.data.plan.current) return 'n/a'
      if (this.data.plan.current == 'ulo') return 'Ultra-Low Overnight'
      if (this.data.plan.current == 'tou') return 'Time-of-Use'
      if (this.data.plan.current == 'tiered') return 'Tiered'
    },
    comparisonPlanName() {
      if (!this.comparisonPlan) return 'n/a'
      if (this.comparisonPlan == 'ulo') return 'Ultra-Low Overnight'
      if (this.comparisonPlan == 'tou') return 'Time-of-Use'
      if (this.comparisonPlan == 'tiered') return 'Tiered'
    }
  },
  filters: {
    currency(value) {
      return '$' + value.toFixed(2)
    }
  }
}
</script>
<style>
.transparent {
  opacity: 0.25;
}
</style>