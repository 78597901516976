import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import firebase from '@/firebase/init'
import { getAuth, onAuthStateChanged } from 'firebase/auth'

Vue.config.productionTip = false

Vue.use(vuetify, {
  iconfont: 'mdi' || 'fa'
})

Vue.mixin({
  methods: {
    go: (route, params) => {
      let link = router.resolve({ name: route })
      if (link.resolved.matched.length > 0) {
        router.push({ name: route, params: params }).catch(error => {
          if (error.name != "NavigationDuplicated" && error.name != 'Error') {
            console.log('ERROR', error)
            // throw error;
          }
        })
      } else {
        router.push({ name: 'NotFound' })
      }
    },
  }
})

let app = null

// wait for firebase auth to init before creating the app
const auth = getAuth()
onAuthStateChanged(auth, (user) => {
  if(!app) { // initialize app if not already created
    app = new Vue({
      router,
      store,
      vuetify,
      render: h => h(App)
    }).$mount('#app')
  }
})