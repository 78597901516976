<template>
  <div>
    <v-tabs vertical>
      <v-tab class="justify-start">
        <v-icon left>mdi-lightbulb</v-icon>
        Idea
      </v-tab>
      <v-tab class="justify-start">
        <v-icon left>mdi-briefcase-account</v-icon>
        Business Case
      </v-tab>
      <v-tab class="justify-start">
        <v-icon left>mdi-bullseye-arrow</v-icon>
        Goals
      </v-tab>
      <v-tab class="justify-start">
        <v-icon left>mdi-memory</v-icon>
        Approach
      </v-tab>
      <v-tab class="justify-start">
        <v-icon left>mdi-check-decagram</v-icon>
        Status
      </v-tab>
      <v-tab class="justify-start">
        <v-icon left>mdi-flag-checkered</v-icon>
        Conclusion
      </v-tab>
      <!-- ------------ Idea ------------ -->
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <h1 class="mb-6">Idea</h1>
            <div v-for="(section, index) in background.idea" :key="'idea-section-'+index">
              <Content :type="section.type" :content="section.content" :colour="section.colour"></Content>
            </div>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <!-- ------------ Business Case ------------ -->
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <h1 class="mb-6">Business Case</h1>
            <div v-for="(section, index) in background.cases" :key="'cases-section-'+index">
              <Content :type="section.type" :content="section.content" :colour="section.colour"></Content>
            </div>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <!-- ------------ Goals ------------ -->
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <h1 class="mb-6">Goals</h1>
            <div v-for="(section, index) in background.goals" :key="'cases-section-'+index">
              <Content :type="section.type" :content="section.content" :colour="section.colour"></Content>
            </div>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <!-- ------------ Approach ------------ -->
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <h1 class="mb-6">Approach</h1>
            <div v-for="(section, index) in background.approach" :key="'approach-section-'+index">
              <Content :type="section.type" :content="section.content" :colour="section.colour"></Content>
            </div>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <!-- ------------ Status ------------ -->
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <h1 class="mb-6">Status</h1>
            <div v-for="(section, index) in background.status" :key="'status-section-'+index">
              <Content :type="section.type" :content="section.content" :colour="section.colour"></Content>
            </div>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <!-- ------------ Conclusion ------------ -->
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <h1 class="mb-6">Conclusion</h1>
            <div v-for="(section, index) in background.conclusion" :key="'idea-section-'+index">
              <Content :type="section.type" :content="section.content" :colour="section.colour"></Content>
            </div>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs>
  </div>
</template>
<script>
import Content from './Content.vue'
export default {
  name: 'Background',
  props: ['background'],
  components: {
    Content
  },
  data() {
    return {
      tabs: null,
      
    }
  },
}
</script>
<style lang="scss">
  .v-item-group .v-slide-group__wrapper {
    // border-right: 1px solid #e0e0e0;
  }
</style>