<template>
    <v-container>
      <h1 class="mb-4">AI Podcast Generation</h1>
      <v-card>
        <v-tabs v-model="tab" class="px-4 pt-4">
          <v-tab>Proof of Concept</v-tab>
          <v-tab>Background</v-tab>
        </v-tabs>
        <v-divider></v-divider>
        <v-card-text class="text-left">
          <v-tabs-items v-model="tab">
            <!-- ------------ Proof of Concept ------------ -->
            <v-tab-item class="pa-6">
              <h2 class="mb-6">Proof of Concept</h2>
              <v-row class="pa-2">
                <v-col v-for="usecase in usecases" :key="usecase.audio" cols="3" xs="12" sm="6" md="3">
                  <v-card class="mx-auto">
                    <v-img
                      class="white--text align-end"
                      height="200px"
                      :src="usecase.image"
                      style="background: #f9f9f9"
                    >
                    </v-img>
                    <v-divider></v-divider>
                    <v-card-text class="text--primary">
                      <strong>{{ usecase.name }}</strong><br>
                      <div class="text-overflow text-caption">{{ usecase.subtitle }}</div>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                      <v-btn v-if="usecase.status == 'stopped'" icon  @click="play(usecase)">
                        <v-icon>mdi-play</v-icon>
                      </v-btn>
                      <v-btn v-if="usecase.status == 'playing'" icon @click="stop()">
                        <v-icon>mdi-stop</v-icon>
                      </v-btn>
                      <div v-if="usecase.status == 'playing'" class="text-caption ml-2">Playing...</div>
                      <v-spacer></v-spacer>
                      <div class="text-overflow text-caption mr-2">({{ usecase.length }})</div>
                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-row>
            </v-tab-item>
            <!-- ------------ Background ------------ -->
            <v-tab-item class="pa-6">
              <h2 class="mb-6">Background</h2>
              <ul>
                <li>Advances in audio generation allows for human voice synthesis.</li>
                <li>While this experiment demonstrates podcasts it can also be used for other audio applications.</li>
              </ul>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
      </v-card>
    </v-container>
  </template>
  <script>
  export default ({
    name: 'CommunicationsPodcasts',
    data: () => ({
      tab: 0,
      usecases: [
        {
          image: 'https://storage.googleapis.com/hydroottawa-audio/music/podcast3.png',
          audio: 'https://storage.googleapis.com/hydroottawa-audio/music/myaccount-podcast.wav',
          name: 'Hydro Ottawa Limited',
          subtitle: 'Source: Website',
          length: '4:17',
          player: null,
          status: 'stopped',
        },
        {
          image: 'https://storage.googleapis.com/hydroottawa-audio/music/podcast3.png',
          audio: 'https://storage.googleapis.com/hydroottawa-audio/music/hiboo-podcast.wav',
          name: 'Hiboo Networks',
          subtitle: 'Source: Website',
          length: '6:45',
          player: null,
          status: 'stopped',
        },
        {
          image: 'https://storage.googleapis.com/hydroottawa-audio/music/podcast3.png',
          audio: 'https://storage.googleapis.com/hydroottawa-audio/music/thinkenergy-podcast.wav',
          name: 'Think Energy Podcast',
          subtitle: 'Current affairs with Francis Bradley',
          length: '5:32',
          player: null,
          status: 'stopped',
        },
        {
          image: 'https://storage.googleapis.com/hydroottawa-audio/music/podcast3.png',
          audio: 'https://storage.googleapis.com/hydroottawa-audio/music/energyfuture-podcast.wav',
          name: 'Energy Future Podcast',
          subtitle: "Ottawa's energy future campaign",
          length: '8:45',
          player: null,
          status: 'stopped',
        },
        // {
        //   image: 'https://storage.googleapis.com/hydroottawa-audio/music/audio.png',
        //   audio: 'https://storage.googleapis.com/hydroottawa-audio/music/envari-podcast.wav',
        //   name: 'Envari',
        //   subtitle: '',
        //   player: null,
        //   status: 'stopped',
        // },
      ]
    }),
    methods: {
      stop() {
        // stop all other players
        this.usecases.forEach(usecase => {
          if (usecase.player) {
            usecase.player.pause()
            usecase.player.currentTime = 0
            usecase.status = 'stopped'
          }
        })
      },
      play(usecase) {
        // stop all other players
        this.stop()
        // play the current player
        usecase.player = new Audio(usecase.audio)
        usecase.player.currentTime = 0
        usecase.player.play()
        // detect when the song is done playing
        usecase.player.onended = () => {
          usecase.status = 'stopped'
        }
        usecase.status = 'playing'
      }
    }
  })
  </script>
  <style lang="scss">
  
  </style>