<template>
  <v-container>
    <h1 class="mb-4">Rate Plan Explorer Prototype</h1>
    <v-card class="mb-4">
      <v-card-title>Rate Plan Selector</v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-select v-model="data.plan.current" :items="plans" item-text="text" item-value="value"></v-select>
      </v-card-text>
    </v-card>
    <v-card>
      <v-card-title>New Rate Plan Explorer Experience</v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-stepper v-model="storyStep">
          <v-stepper-items>
            <!-- ---------------------- Step 1: Available Rate Plans ---------------------- -->
            <v-stepper-content step="1">
              <RateExplorerStep1 :data="data" @nextStep="nextStep()"></RateExplorerStep1>
            </v-stepper-content>
            <!-- ---------------------- Step 2: Your Current Plan ---------------------- -->
            <v-stepper-content step="2">
              <RateExplorerStep2 :data="data"></RateExplorerStep2>
            </v-stepper-content>
            <!-- ---------------------- Step 3: Plan Comparison ---------------------- -->
            <v-stepper-content step="3">
              <RateExplorerStep3 :data="data"></RateExplorerStep3>
            </v-stepper-content>
            <!-- ---------------------- Stepper Controls ---------------------- -->
            <div class="d-flex flex-row ma-6">
              <v-btn v-if="storyStep > 1" color="primary" text @click="storyStep--">
                Previous
              </v-btn>
              <div class="flex-1">
                <div v-if="storyStep == 1">Continue exploring to see how this applies to your data.</div>
                <div v-if="storyStep == 2">Continue to see how the plans compare to your data.</div>
              </div>
              <v-btn v-if="storyStep == 1" @click="storyStep++" color="#005794" dark>Explore your data</v-btn>
              <v-btn v-if="storyStep == 2" @click="storyStep++" color="#005794" dark>Compare plans</v-btn>
            </div>
          </v-stepper-items>
        </v-stepper>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script lang="ts">
import axios from 'axios'
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
} from 'chart.js'
import { Bar } from 'vue-chartjs'
import RateExplorerStep1 from '@/components/RateExplorerStep1.vue'
import RateExplorerStep2 from '@/components/RateExplorerStep2.vue'
import RateExplorerStep3 from '@/components/RateExplorerStep3.vue'
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

const gradients = [
  ['#222'],
  ['#42b3f4'],
  ['red', 'orange', 'yellow'],
  ['purple', 'violet'],
  ['#00c6ff', '#F0F', '#FF0'],
  ['#f72047', '#ffd200', '#1feaea'],
]
export default ({
  name: 'RateExplorer',
  components: {
    Bar, RateExplorerStep1, RateExplorerStep2, RateExplorerStep3
  },
  data: () => ({
    currentPlan: 'tiered',
    comparisonPlan: '',

    panel: 0,

    plans: [
      { 'value': 'tou', 'text': 'Time-of-Use' },
      { 'value': 'tiered', 'text': 'Tiered' },
      { 'value': 'ulo', 'text': 'Ultra-Low Overnight' },
    ],
    seasons: [
      'All Year',
      'Winter (Nov - Apr)',
      'Summer (May - Oct)'
    ],
    scales: [
      'Hourly',
      'Monthly',
      'Yearly',
    ],
    data: {
      plan: {
        current: 'tiered',
      },
      cost: {
        summary: {
          total: 12340,
          maximum: 1234,
          minimum: 234,
          average: 789,
          months: 12,
        },
        tiered: {
          summer: {
            tier1: 0,
            tier2: 0,
            sum: 0
          },
          winter: {
            tier1: 0,
            tier2: 0,
            sum: 0
          },
          total: {
            tier1: 0,
            tier2: 0,
            sum: 0
          }
        },
        tou: {
          winter: {
            on: 0,
            mid: 0,
            off: 0,
            sum: 0,
          },
          summer: {
            on: 0,
            mid: 0,
            off: 0,
            sum: 0,
          },
          total: {
            on: 0,
            mid: 0,
            off: 0,
            sum: 0,
          }
        },
        ulo: {
          summer: {
            overnight: 0,
            weekend: 0,
            mid: 0,
            on: 0,
            sum: 0,
          },
          winter: {
            overnight: 0,
            weekend: 0,
            mid: 0,
            on: 0,
            sum: 0,
          },
          total: {
            overnight: 0,
            weekend: 0,
            mid: 0,
            on: 0,
            sum: 0,
          },
        },
      },
      rates: {
        tiered: {
          summer: {
            tier1: 0.103,
            tier2: 0.125,
            threshold: 600,
          },
          winter: {
            tier1: 0.103,
            tier2: 0.125,
            threshold: 1000,
          }
        },
        tou: {
          summer: {
            on: 0.182,
            mid: 0.122,
            off: 0.087,
          },
          winter: {
            on: 0.182,
            mid: 0.122,
            off: 0.087,
          }
        },
        ulo: {
          on: 0.286,
          mid: 0.122,
          weekend: 0.087,
          overnight: 0.028,
        }
      },
      consumption: {
        summary: {
          total: 12340,
          maximum: 1234,
          minimum: 234,
          average: 789,
          months: 12,
          tiered: {
            summer: {
              tier1: 0,
              tier2: 0,
              sum: 0
            },
            winter: {
              tier1: 0,
              tier2: 0,
              sum: 0
            },
            total: {
              tier1: 0,
              tier2: 0,
              sum: 0
            }
          },
          tou: {
            winter: {
              on: 0,
              mid: 0,
              off: 0,
              sum: 0,
            },
            summer: {
              on: 0,
              mid: 0,
              off: 0,
              sum: 0,
            },
            total: {
              on: 0,
              mid: 0,
              off: 0,
              sum: 0,
            }
          },
          ulo: {
            summer: {
              overnight: 0,
              weekend: 0,
              mid: 0,
              on: 0,
              sum: 0,
            },
            winter: {
              overnight: 0,
              weekend: 0,
              mid: 0,
              on: 0,
              sum: 0,
            },
            total: {
              overnight: 0,
              weekend: 0,
              mid: 0,
              on: 0,
              sum: 0,
            },
          }
        },
        monthly: [
          {
            name: 'January',
            year: 2023,
            tou: {
              rates: {
                on: 0.2,
                mid: 0.1,
                off: 0.05,
              },
              consumption: {
                on: 1234,
                mid: 1234,
                off: 1234,
              },
            },
            tiered: {
              rates: {
                tier1: 0.2,
                tier2: 0.1,
              },
              threshold: 600,
              consumption: {
                tier1: 1234,
                tier2: 1234,
              },
            },
            ulo: {
              rates: {
                on: 0.2,
                mid: 0.1,
                weekend: 0.05,
                overnight: 0.01,
              },
              consumption: {
                on: 1234,
                mid: 1234,
                weekend: 1234,
                overnight: 1234,
              },
            },
          }

        ]
      },

    },

    sampleData: [],
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    // labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],

    raw_data: {},
    storyStep: 1,
    selectedPlan: '',

    
    
    // data: {
    //   labels: ['January', 'February', 'March'],
    //   datasets: [
    //     // { data: [40, 20, 12, 52, 40, 20, 12, 69, 40, 20, 12, 22 ] }
    //     {
    //       label: 'TOU Off-Peak',
    //       data: [40, 20, 12],
    //       // backgroundColor: '#5cb85c',
    //       backgroundColor: '#566E1C', // OEB
    //       stack: 'Time-of-Use',
    //     },
    //     {
    //       label: 'TOU Mid-Peak',
    //       data: [12, 40, 20],
    //       // backgroundColor: '#f0ad4e',
    //       backgroundColor: '#FFD945', // OEB
    //       stack: 'Time-of-Use',
    //     },
    //     {
    //       label: 'TOU On-Peak',
    //       data: [20, 40, 12],
    //       // backgroundColor: '#d9534f',
    //       backgroundColor: '#D73A0F', // OEB
    //       stack: 'Time-of-Use',
    //     },

    //     {
    //       label: 'Tier 1',
    //       data: [20, 40, 12],
    //       // backgroundColor: '#005b9b',
    //       backgroundColor: '#566E1C', // OEB
    //       stack: 'Tiered',
    //     },
    //     {
    //       label: 'Tier 2',
    //       data: [20, 40, 12],
    //       // backgroundColor: '#003f6b',
    //       backgroundColor: '#D73A0F', // OEB
    //       stack: 'Tiered',
    //     },

    //     {
    //       label: 'ULO On-Peak',
    //       data: [20, 40, 12],
    //       backgroundColor: '#8E1A60', // OEB
    //       stack: 'Ultra Low',
    //     },
    //     {
    //       label: 'ULO Mid-Peak',
    //       data: [12, 40, 12],
    //       backgroundColor: '#FFD945',
    //       stack: 'Ultra Low',
    //     },
    //     {
    //       label: 'ULO Weekend Off-Peak',
    //       data: [20, 40, 12],
    //       backgroundColor: '#566E1C', // OEB
    //       stack: 'Ultra Low',
    //     },
    //     {
    //       label: 'ULO Ultra-Low Overnight',
    //       data: [20, 40, 12],
    //       backgroundColor: '#018FBE', // OEB
    //       stack: 'Ultra Low',
    //     },
    //   ]
    // },
    options: {
      responsive: true
    },

    showGraph: true,
    width: 4,
    radius: 10,
    padding: 8,
    lineCap: 'round',
    gradient: gradients[5],
    value: [0, 2, 5, 9, 5, 10, 3, 5, 0, 0, 1, 8, 2, 9, 0],
    gradientDirection: 'top',
    gradients,
    fill: false,
    type: 'trend',
    autoLineWidth: false,
  }),
  created() {
    for( var i=0; i<12; i++ ) {
      this.sampleData.push({
        touOn: Math.floor(Math.random() * 30),
        touMid: Math.floor(Math.random() * 30),
        touOff: Math.floor(Math.random() * 30),
        tou: 0,

        tier1: 30,
        tier2: Math.floor(Math.random() * 50),
        tiered: 0,

        uloOn: Math.floor(Math.random() * 25),
        uloMid: Math.floor(Math.random() * 25),
        uloOff: Math.floor(Math.random() * 25),
        uloOvernight: Math.floor(Math.random() * 25),
        ulo: 0,
      })
      
      this.sampleData[i].tiered = this.sampleData[i].tier1 + this.sampleData[i].tier2
      this.data.consumption.summary.tiered.total.sum += this.sampleData[i].tiered
      this.data.consumption.summary.tiered.total.tier1 += this.sampleData[i].tier1
      this.data.consumption.summary.tiered.total.tier2 += this.sampleData[i].tier2
      this.data.cost.tiered.total.tier1 += this.sampleData[i].tier1 * this.data.rates.tiered.winter.tier1
      this.data.cost.tiered.total.tier2 += this.sampleData[i].tier2 * this.data.rates.tiered.winter.tier2
      this.data.cost.tiered.total.sum += this.sampleData[i].tier1 * this.data.rates.tiered.winter.tier1 + this.sampleData[i].tier2 * this.data.rates.tiered.winter.tier2
      
      this.sampleData[i].tou = this.sampleData[i].touOn + this.sampleData[i].touMid + this.sampleData[i].touOff
      this.data.consumption.summary.tou.total.sum += this.sampleData[i].tou
      this.data.consumption.summary.tou.total.on += this.sampleData[i].touOn
      this.data.consumption.summary.tou.total.mid += this.sampleData[i].touMid
      this.data.consumption.summary.tou.total.off += this.sampleData[i].touOff
      this.data.cost.tou.total.on += this.sampleData[i].touOn * this.data.rates.tou.winter.on
      this.data.cost.tou.total.mid += this.sampleData[i].touMid * this.data.rates.tou.winter.mid
      this.data.cost.tou.total.off += this.sampleData[i].touOff * this.data.rates.tou.winter.off
      this.data.cost.tou.total.sum += this.sampleData[i].touOn * this.data.rates.tou.winter.on + this.sampleData[i].touMid * this.data.rates.tou.winter.mid + this.sampleData[i].touOff * this.data.rates.tou.winter.off 
      
      this.sampleData[i].ulo = this.sampleData[i].uloOn + this.sampleData[i].uloMid + this.sampleData[i].uloOff + this.sampleData[i].uloOvernight
      this.data.consumption.summary.ulo.total.sum += this.sampleData[i].ulo
      this.data.consumption.summary.ulo.total.on += this.sampleData[i].uloOn
      this.data.consumption.summary.ulo.total.mid += this.sampleData[i].uloMid
      this.data.consumption.summary.ulo.total.weekend += this.sampleData[i].uloOff
      this.data.consumption.summary.ulo.total.overnight += this.sampleData[i].uloOvernight
      this.data.cost.ulo.total.on += this.sampleData[i].uloOn * this.data.rates.ulo.on
      this.data.cost.ulo.total.mid += this.sampleData[i].uloMid * this.data.rates.ulo.mid
      this.data.cost.ulo.total.weekend += this.sampleData[i].uloOff * this.data.rates.ulo.weekend
      this.data.cost.ulo.total.overnight += this.sampleData[i].uloOvernight * this.data.rates.ulo.overnight
      this.data.cost.ulo.total.sum += this.sampleData[i].uloOn * this.data.rates.ulo.on + this.sampleData[i].uloMid * this.data.rates.ulo.mid + this.sampleData[i].uloOff * this.data.rates.ulo.weekend + this.sampleData[i].uloOvernight * this.data.rates.ulo.overnight

      this.data.sampleData = this.sampleData
    }
  },
  methods: {
    nextStep() {
      console.log('next!')
      this.storyStep++
    },
  }
})
</script>
<style lang="scss">
  .flex-1 {
    flex: 1;
  }
  .flex-2 {
    flex: 2;
  }
  .rate-option-box {
    background: #d1eae8;
  }
  .rate-option-box.active {
    border: 1px solid #ccc;
    background: white;
  }
  .rate-bar {
    width: 100%;
    margin: 0 1px;
  }
  .bar-segment {
    width: 100%;
  }
  .x-axis {
    border-top: 1px solid #ccc;
  }
  .y-axis {
    border-right: 1px solid #ccc;
  }
  .axis-marker {
    border-bottom: 1px solid #ccc;
    width: 10px;
  }
  .tou-on-segment {
    // background: #D73A0F; // OEB
    background: #D15757; // CE
  }
  .tou-mid-segment {
    // background: #FFD945; // OEB
    background: #EDAD5C; // CE
  }
  .tou-off-segment {
    // background: #566E1C; // OEB
    background: #529B58; // CE
  }
  .tiered-tier1-segment {
    // background: #566E1C; // OEB
    background: #48ADA2; // CE
  }
  .tiered-tier2-segment {
    // background: #D73A0F; // OEB
    background: #668397; // CE
  }
  .ulo-on-segment {
    // background: #8E1A60; // OEB
    background: #3F2A56; // CE
  }
  .ulo-mid-segment {
    // background: #FFD945;
    background: #F9DB63; // CE
  }
  .ulo-weekend-segment {
    // background: #566E1C; // OEB
    background: #5C6E2B; // CE
  }
  .ulo-overnight-segment {
    // background: #018FBE; // OEB
    background: #BBC7D6; // CE
  }
  .legend .swatch {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
  .horizontal-bar {
    height: 50px;
  }
</style>