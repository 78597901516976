<template>
  <v-app>
    <Header :user="user" :key="user && user.roles ? user.roles.join(':') : 'Default'" @logout="logout()"></Header>
    <router-view :user="user" />
  </v-app>
</template>
<script>
import { getAuth, onAuthStateChanged, sendEmailVerification, getAdditionalUserInfo, signOut } from 'firebase/auth'
import { getFirestore, doc, setDoc, onSnapshot } from 'firebase/firestore'
import Header from './components/Header.vue'
export default {
  name: 'App',
  components: {
    Header
  },
  data: () => ({
    user: null,
    userListener: null,
  }),
  created() {
    const auth = getAuth()
    onAuthStateChanged(auth, (user) => {
      this.user = null
      this.$forceUpdate()
      if (user) {
        // Check if the user email is @hydroottawa.com or @google.com
        const email = user.email
        if (email.includes('@hydroottawa.com') || email.includes('@google.com') || email == 'aaronfarr@gmail.com') {
          // ---------------------- New User ----------------------
          // Check if the user is new, if so add them to the database
          const db = getFirestore()
          const userRef = doc(db, 'users', user.uid)
          this.userListener = onSnapshot(userRef, (doc) => {
            this.user = null
            if (!doc.exists()) {
              console.log('New User')
              let defaultRoles = ['Employee']
              setDoc(userRef, {
                id: user.uid,
                email: user.email,
                roles: defaultRoles,
                profile: {
                  name: user.displayName,
                  photo: user.photoURL,
                  emailVerified: user.emailVerified,
                },
                created: user.metadata.creationTime,
              })
              this.user = user
              this.user.roles = defaultRoles
            } else {
              // ---------------------- Returning User ----------------------
              // console.log('Returning User')
              this.user = user
              this.user.roles = doc.data().roles
            }
          })
          // if the current route is Login, go to Dashboard
          if (this.$route.name === 'Landing' || this.$route.name === 'Login') {
            this.go('Dashboard')
          }
          console.log('auth state changed', user)
        } else {
          this.logout()
        }
      }
    })
  },
  methods: {
    logout() {
      const auth = getAuth()
      signOut(auth).then(() => { // Sign-out successful.
        this.go('Landing')
      }).catch((error) => { // An error happened.
        console.log('Something went wrong. Could not sign out.')
        this.go('Unknown')
      });
    }
  }
}
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background: url('@/assets/subtle-dots.png');
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
.view-container {
  background: red;
}
.flex-1 {
  flex: 1;
}
.flex-2 {
  flex: 2;
}
</style>
