<template>
  <div class="d-flex flex-column">
    <div class="text-h5">Your Current Plan</div>
    <div class="py-4">
      Using your consumption data we can help determine which rate plan is best for you.
    </div>
    <div class="d-flex flex-row">
      <div class="flex-1">
        <!-- ------------ Graph ------------ -->
        <div class="d-flex flex-row mr-4" style="height: 400px;">
          <div class="d-flex flex-column">
            <div class="flex-1 d-flex flex-column y-axis">
              <div class="flex-1 d-flex flex-row align-center justify-end">
                <div class="mr-1">5</div>
                <div class="axis-marker"></div>
              </div>
              <div class="flex-1 d-flex flex-row align-center justify-end">
                <div class="mr-1">4</div>
                <div class="axis-marker"></div>
              </div>
              <div class="flex-1 d-flex flex-row align-center justify-end">
                <div class="mr-1">3</div>
                <div class="axis-marker"></div>
              </div>
              <div class="flex-1 d-flex flex-row align-center justify-end">
                <div class="mr-1">2</div>
                <div class="axis-marker"></div>
              </div>
              <div class="flex-1 d-flex flex-row align-center justify-end">
                <div class="mr-1">1</div>
                <div class="axis-marker"></div>
              </div>
            </div>
            <div class="white--text">x</div>
          </div>
          <!-- ------ Bar ------ -->
          <div class="flex-1 d-flex flex-column" v-for="(month, index) in data.sampleData" :key="'month'+index">
            <div class="flex-1 d-flex px-1 flex-row">
              <!-- TOU Bar -->
              <div v-if="data.plan.current== 'tou'" class="rate-bar tou-bar d-flex flex-column justify-end">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div class="bar-segment tou-on-segment" :style="{height: month.touOn + '%'}" v-bind="attrs" v-on="on"></div>
                  </template>
                  <span>{{ month.touOn }}</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div class="bar-segment tou-mid-segment" :style="{height: month.touMid + '%'}" v-bind="attrs" v-on="on"></div>
                  </template>
                  <span>{{ month.touMid }}</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div class="bar-segment tou-off-segment" :style="{height: month.touOff + '%'}" v-bind="attrs" v-on="on"></div>
                  </template>
                  <span>{{ month.touOff }}</span>
                </v-tooltip>
              </div>
              <!-- Tiered Bar -->
              <div v-if="data.plan.current == 'tiered'" class="rate-bar tiered-bar d-flex flex-column justify-end">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div class="bar-segment tiered-tier2-segment" :style="{height: month.tier2 + '%'}" v-bind="attrs" v-on="on"></div>
                  </template>
                  <span>{{ month.tier2 }}</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div class="bar-segment tiered-tier1-segment" :style="{height: month.tier1 + '%'}" v-bind="attrs" v-on="on"></div>
                  </template>
                  <span>{{ month.tier1 }}</span>
                </v-tooltip>
              </div>
              <!-- ULO Bar -->
              <div v-if="data.plan.current == 'ulo'" class="rate-bar ulo-bar d-flex flex-column justify-end">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div class="bar-segment ulo-on-segment" :style="{height: month.uloOn + '%'}" v-bind="attrs" v-on="on"></div>
                  </template>
                  <span>{{ month.uloOn }}</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div class="bar-segment ulo-mid-segment" :style="{height: month.uloMid + '%'}" v-bind="attrs" v-on="on"></div>
                  </template>
                  <span>{{ month.uloMid }}</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div class="bar-segment ulo-weekend-segment" :style="{height: month.uloOff + '%'}" v-bind="attrs" v-on="on"></div>
                  </template>
                  <span>{{ month.uloOff }}</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div class="bar-segment ulo-overnight-segment" :style="{height: month.uloOvernight + '%'}" v-bind="attrs" v-on="on"></div>
                  </template>
                  <span>{{ month.uloOvernight }}</span>
                </v-tooltip>
              </div>
            </div>
            <div class="label x-axis font-italic text-caption">{{ labels[index] }}</div>
          </div>
        </div>
        <div class="legend pa-4 ml-4 d-flex flex-row">
          <!-- TOU Legend -->
          <div v-if="data.plan.current == 'tou'" class="d-flex flex-row">
            <div class="d-flex flex-row mr-4">
              <div class="swatch tou-on-segment"></div>
              <div class="text-caption">On-Peak</div>
            </div>
            <div class="d-flex flex-row mr-4">
              <div class="swatch tou-mid-segment"></div>
              <div class="text-caption">Mid-Peak</div>
            </div>
            <div class="d-flex flex-row mr-4">
              <div class="swatch tou-off-segment"></div>
              <div class="text-caption">Off-Peak</div>
            </div>
          </div>
          <!-- ULO Legend -->
          <div v-if="data.plan.current == 'ulo'" class="d-flex flex-row">
            <div class="d-flex flex-row mr-4">
              <div class="swatch ulo-on-segment"></div>
              <div class="text-caption">ULO On-Peak</div>
            </div>
            <div class="d-flex flex-row mr-4">
              <div class="swatch ulo-mid-segment"></div>
              <div class="text-caption">ULO Mid-Peak</div>
            </div>
            <div class="d-flex flex-row mr-4">
              <div class="swatch ulo-weekend-segment"></div>
              <div class="text-caption">Weekend Off-Peak</div>
            </div>
            <div class="d-flex flex-row mr-4">
              <div class="swatch ulo-overnight-segment"></div>
              <div class="text-caption">Ultra-Low Overnight</div>
            </div>
          </div>
          <!-- Tiered Legend -->
          <div v-if="data.plan.current == 'tiered'" class="d-flex flex-row">
            <div class="d-flex flex-row mr-4">
              <div class="swatch tiered-tier1-segment"></div>
              <div class="text-caption">Tier 1</div>
            </div>
            <div class="d-flex flex-row mr-4">
              <div class="swatch tiered-tier2-segment"></div>
              <div class="text-caption">Tier 2</div>
            </div>
          </div>
        </div>
      </div>
      <v-card class="flex-1 pa-4 d-flex flex-column" flat color="grey lighten-3">
        <div class="overline">Insights</div>
        <v-btn-toggle
          v-model="display"
          tile
          color="#005794"
          group
        >
          <v-btn value="consumption">
            By Consumption
          </v-btn>
          <v-btn value="cost">
            By Cost
          </v-btn>
          <v-btn value="shift">
            With Shift
          </v-btn>
        </v-btn-toggle>
        <div class="d-flex flex-column">
          <div v-if="display == 'consumption'" class="d-flex flex-column">
            <div v-if="data.plan.current == 'tiered'" class="d-flex flex-row align-center my-2">
              <div class="mr-4 text-right">Tier 1</div>
              <div class="horizontal-bar tiered-tier1-segment" :style="{ 'width': 100*(data.consumption.summary.tiered.total.tier1 / data.consumption.summary.tiered.total.sum) + '%' }"></div>
              <div class="flex-1"></div>
              <div>{{ Math.round(100*(data.consumption.summary.tiered.total.tier1 / data.consumption.summary.tiered.total.sum)) + '%'}}</div>
            </div>
            <div v-if="data.plan.current == 'tiered'" class="d-flex flex-row align-center my-2">
              <div class="mr-4 text-right">Tier 2</div>
              <div class="horizontal-bar tiered-tier2-segment" :style="{ 'width': 100*(data.consumption.summary.tiered.total.tier2 / data.consumption.summary.tiered.total.sum) + '%' }"></div>
              <div class="flex-1"></div>
              <div>{{ Math.round(100*(data.consumption.summary.tiered.total.tier2 / data.consumption.summary.tiered.total.sum)) + '%'}}</div>
            </div>
          </div>
          <div v-if="display == 'cost'" class="d-flex flex-column">
            <div v-if="data.plan.current == 'tiered'" class="d-flex flex-row align-center my-2">
              <div class="mr-4 text-right">Tier 1</div>
              <div class="horizontal-bar tiered-tier1-segment" :style="{ 'width': 100*(data.cost.tiered.total.tier1 / data.cost.tiered.total.sum) + '%' }"></div>
              <div class="flex-1"></div>
              <div>{{ Math.round(100*(data.cost.tiered.total.tier1 / data.cost.tiered.total.sum)) + '%'}}</div>
            </div>
            <div v-if="data.plan.current == 'tiered'" class="d-flex flex-row align-center my-2">
              <div class="mr-4 text-right">Tier 2</div>
              <div class="horizontal-bar tiered-tier2-segment" :style="{ 'width': 100*(data.cost.tiered.total.tier2 / data.cost.tiered.total.sum) + '%' }"></div>
              <div class="flex-1"></div>
              <div>{{ Math.round(100*(data.cost.tiered.total.tier2 / data.cost.tiered.total.sum)) + '%'}}</div>
            </div>
          </div>
          <div v-if="display == 'consumption'" class="d-flex flex-column">
            <div v-if="data.plan.current == 'tou'" class="d-flex flex-row align-center my-2">
              <div class="mr-4 text-right" style="min-width: 70px">Off-Peak</div>
              <div class="horizontal-bar tou-off-segment" :style="{ 'width': 100*(data.consumption.summary.tou.total.off / data.consumption.summary.tou.total.sum) + '%' }"></div>
              <div class="flex-1"></div>
              <div>{{ Math.round(100*(data.consumption.summary.tou.total.off / data.consumption.summary.tou.total.sum)) + '%'}}</div>
            </div>
            <div v-if="data.plan.current == 'tou'" class="d-flex flex-row align-center my-2">
              <div class="mr-4 text-right" style="min-width: 70px">Mid-Peak</div>
              <div class="horizontal-bar tou-mid-segment" :style="{ 'width': 100*(data.consumption.summary.tou.total.mid / data.consumption.summary.tou.total.sum) + '%' }"></div>
              <div class="flex-1"></div>
              <div>{{ Math.round(100*(data.consumption.summary.tou.total.mid / data.consumption.summary.tou.total.sum)) + '%'}}</div>
            </div>
            <div v-if="data.plan.current == 'tou'" class="d-flex flex-row align-center my-2">
              <div class="mr-4 text-right" style="min-width: 70px">On-Peak</div>
              <div class="horizontal-bar tou-on-segment" :style="{ 'width': 100*(data.consumption.summary.tou.total.on / data.consumption.summary.tou.total.sum) + '%' }"></div>
              <div class="flex-1"></div>
              <div>{{ Math.round(100*(data.consumption.summary.tou.total.on / data.consumption.summary.tou.total.sum)) + '%'}}</div>
            </div>
          </div>
          <div v-if="display == 'cost'" class="d-flex flex-column">
            <div v-if="data.plan.current == 'tou'" class="d-flex flex-row align-center my-2">
              <div class="mr-4 text-right" style="min-width: 70px">Off-Peak</div>
              <div class="horizontal-bar tou-off-segment" :style="{ 'width': 100*(data.cost.tou.total.off / data.cost.tou.total.sum) + '%' }"></div>
              <div class="flex-1"></div>
              <div>{{ Math.round(100*(data.cost.tou.total.off / data.cost.tou.total.sum)) + '%'}}</div>
            </div>
            <div v-if="data.plan.current == 'tou'" class="d-flex flex-row align-center my-2">
              <div class="mr-4 text-right" style="min-width: 70px">Mid-Peak</div>
              <div class="horizontal-bar tou-mid-segment" :style="{ 'width': 100*(data.cost.tou.total.mid / data.cost.tou.total.sum) + '%' }"></div>
              <div class="flex-1"></div>
              <div>{{ Math.round(100*(data.cost.tou.total.mid / data.cost.tou.total.sum)) + '%'}}</div>
            </div>
            <div v-if="data.plan.current == 'tou'" class="d-flex flex-row align-center my-2">
              <div class="mr-4 text-right" style="min-width: 70px">On-Peak</div>
              <div class="horizontal-bar tou-on-segment" :style="{ 'width': 100*(data.cost.tou.total.on / data.cost.tou.total.sum) + '%' }"></div>
              <div class="flex-1"></div>
              <div>{{ Math.round(100*(data.cost.tou.total.on / data.cost.tou.total.sum)) + '%'}}</div>
            </div>
          </div>
          <div v-if="display == 'consumption'" class="d-flex flex-column">
            <div v-if="data.plan.current == 'ulo'" class="d-flex flex-row align-center my-2">
              <div class="mr-4 text-right" style="min-width: 140px">On-Peak</div>
              <div class="horizontal-bar ulo-on-segment" :style="{ 'width': 100*(data.consumption.summary.ulo.total.on / data.consumption.summary.ulo.total.sum) + '%' }"></div>
              <div class="flex-1"></div>
              <div>{{ Math.round(100*(data.consumption.summary.ulo.total.on / data.consumption.summary.ulo.total.sum)) + '%'}}</div>
            </div>
            <div v-if="data.plan.current == 'ulo'" class="d-flex flex-row align-center my-2">
              <div class="mr-4 text-right" style="min-width: 140px">Mid-Peak</div>
              <div class="horizontal-bar ulo-mid-segment" :style="{ 'width': 100*(data.consumption.summary.ulo.total.mid / data.consumption.summary.ulo.total.sum) + '%' }"></div>
              <div class="flex-1"></div>
              <div>{{ Math.round(100*(data.consumption.summary.ulo.total.mid / data.consumption.summary.ulo.total.sum)) + '%'}}</div>
            </div>
            <div v-if="data.plan.current == 'ulo'" class="d-flex flex-row align-center my-2">
              <div class="mr-4 text-right" style="min-width: 140px">Weekend Off-Peak</div>
              <div class="horizontal-bar ulo-weekend-segment" :style="{ 'width': 100*(data.consumption.summary.ulo.total.weekend / data.consumption.summary.ulo.total.sum) + '%' }"></div>
              <div class="flex-1"></div>
              <div>{{ Math.round(100*(data.consumption.summary.ulo.total.weekend / data.consumption.summary.ulo.total.sum)) + '%'}}</div>
            </div>
            <div v-if="data.plan.current == 'ulo'" class="d-flex flex-row align-center my-2">
              <div class="mr-4 text-right" style="min-width: 140px">Ultra-low overnight</div>
              <div class="horizontal-bar ulo-overnight-segment" :style="{ 'width': 100*(data.consumption.summary.ulo.total.overnight / data.consumption.summary.ulo.total.sum) + '%' }"></div>
              <div class="flex-1"></div>
              <div>{{ Math.round(100*(data.consumption.summary.ulo.total.overnight / data.consumption.summary.ulo.total.sum)) + '%'}}</div>
            </div>
          </div>
          <div v-if="display == 'cost'" class="d-flex flex-column">
            <div v-if="data.plan.current == 'ulo'" class="d-flex flex-row align-center my-2">
              <div class="mr-4 text-right" style="min-width: 140px">On-Peak</div>
              <div class="horizontal-bar ulo-on-segment" :style="{ 'width': 100*(data.cost.ulo.total.on / data.cost.ulo.total.sum) + '%' }"></div>
              <div class="flex-1"></div>
              <div>{{ Math.round(100*(data.cost.ulo.total.on / data.cost.ulo.total.sum)) + '%'}}</div>
            </div>
            <div v-if="data.plan.current == 'ulo'" class="d-flex flex-row align-center my-2">
              <div class="mr-4 text-right" style="min-width: 140px">Mid-Peak</div>
              <div class="horizontal-bar ulo-mid-segment" :style="{ 'width': 100*(data.cost.ulo.total.mid / data.cost.ulo.total.sum) + '%' }"></div>
              <div class="flex-1"></div>
              <div>{{ Math.round(100*(data.cost.ulo.total.mid / data.cost.ulo.total.sum)) + '%'}}</div>
            </div>
            <div v-if="data.plan.current == 'ulo'" class="d-flex flex-row align-center my-2">
              <div class="mr-4 text-right" style="min-width: 140px">Weekend Off-Peak</div>
              <div class="horizontal-bar ulo-weekend-segment" :style="{ 'width': 100*(data.cost.ulo.total.weekend / data.cost.ulo.total.sum) + '%' }"></div>
              <div class="flex-1"></div>
              <div>{{ Math.round(100*(data.cost.ulo.total.weekend / data.cost.ulo.total.sum)) + '%'}}</div>
            </div>
            <div v-if="data.plan.current == 'ulo'" class="d-flex flex-row align-center my-2">
              <div class="mr-4 text-right" style="min-width: 140px">Ultra-low overnight</div>
              <div class="horizontal-bar ulo-overnight-segment" :style="{ 'width': 100*(data.cost.ulo.total.overnight / data.cost.ulo.total.sum) + '%' }"></div>
              <div class="flex-1"></div>
              <div>{{ Math.round(100*(data.cost.ulo.total.overnight / data.cost.ulo.total.sum)) + '%'}}</div>
            </div>
          </div>
        </div>
        <div class="py-4 text-caption font-italic">
          Based on the last X months of your consumption data.
        </div>
        <v-sheet class="my-2 pa-4" color="white">
          <div class="text-caption">The tiered rate plan is ideal for those who minimize the amount of Tier 2 usage.</div>
          <div v-if="(data.consumption.summary.tiered.total.tier2 / data.consumption.summary.tiered.total.sum) > 0.25" class="">
            <div>According to your data, <span class="font-italic">you have significant usage in the Tier 2 range</span>.</div>
            <div class="my-4 font-weight-bold">Switching to a time-of-use rate plan could save you $30 per year.</div>
            <v-btn class="my-2">Explore Alternative</v-btn>
          </div>
          <div v-else class="">
            <div>According to your data, <span class="font-italic">you have low usage in the Tier 2 range</span> so it is a good fit for you.</div>
            <div class="my-4 font-weight-bold">Switching to another plan could cost you more money.</div>
            <v-btn class="my-2">Explore Alternative</v-btn>
          </div>
        </v-sheet>
      </v-card>
    </div>
  </div>
</template>
<script>
export default {
  name: 'RateExplorerStep2',
  props: ['data'],
  data() {
    return {
      step: 2,
      labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      display: 'consumption',
      insights: {
        tou: 'consumption',
        tiered: 'consumption',
        ulo: 'consumption'
      }
    }
  },
  created() {
    
  },
  methods: {
    nextStep() {
      this.$emit('nextStep', this.step + 1)
    }
  },
  filters: {
    currency(value) {
      return '$' + value.toFixed(2)
    }
  }
}
</script>