<template>
    <v-container>
      <h1 class="mb-4">Artificial Agent</h1>
      <v-card>
        <v-card-title>Experimental</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-alert type="info">
            This tool will be the latest interface to Hydro Ottawa's customer service LLM. Responses should not be taken as fact.
          </v-alert>
        </v-card-text>
      </v-card>
    </v-container>
  </template>
  <script>
  export default ({
    name: 'CustomerServiceArtificialAgent',
    data: () => ({
      
    })
  })
  </script>
  <style lang="scss">
  
  </style>